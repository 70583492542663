import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format, parse, isValid, toDate } from "date-fns";
import { Field } from "react-final-form";
import { pt, enGB, fr } from "date-fns/locale";
import SvgIconCalendar from "../../icons/Calendar";

const supportedLang = {
  pt: { locale: "pt", dateLocale: pt },
  en: { locale: "en", dateLocale: enGB },
  fr: { locale: "fr", dateLocale: fr },
};

interface DatepickerProps {
  name: string;
  onBlurCapture?: (e: { target: { name: string; value: string } }) => void;
  validate?: (value: string | undefined) => string | undefined;
  disabled?: boolean;
  min?: Date;
  max?: Date;
  optionalText?: string;
  monthOnly?: boolean;
  setDay?: (day: string) => void;
  setMonth?: (month: string) => void;
  setYear?: (year: string) => void;
  initialValue?: string;
}

export const Datepicker = (props: DatepickerProps) => {
  type ObjectKey = keyof typeof supportedLang;
  const language = window.hph_locale
    ? window.hph_locale.indexOf("-")
      ? window.hph_locale.split("-")[0]
      : "en"
    : ("en" as ObjectKey);
  registerLocale(
    supportedLang[language as ObjectKey].locale,
    supportedLang[language as ObjectKey].dateLocale
  );

  const DateFormats = {
    InputOnChangeFormat: "yyyy-MM-dd",
    datePicker: "dd/MM/yyyy",
    formFormat: "yyyy-MM-dd",
    placeHolderKey: "DD/MM/YYYY",
  };

  return (
    <div className="mt-8 flex flex-row">
      <Field
        name={props.name}
        validate={props.validate}
        initialValue={props.initialValue}
      >
        {({ input }) => {
          let isValidDate = false;
          let selected = null;

          if (input.value) {
            const parsedDate = parse(
              input.value as unknown as string,
              DateFormats.formFormat,
              new Date()
            );
            isValidDate = isValid(parsedDate);
            selected = isValidDate ? toDate(parsedDate) : null;
          }

          return (
            <>
              <DatePicker
                id={input.name}
                name={input.name}
                disabled={props.disabled}
                dateFormat={DateFormats.datePicker}
                showMonthYearPicker={props.monthOnly}
                selected={selected}
                onBlur={input.onBlur}
                customInput={
                  <div className="flex w-7 ">
                    <SvgIconCalendar />
                  </div>
                }
                onChange={(date) => {
                  if (isValid(date)) {
                    const validDate = format(
                      date as Date,
                      DateFormats.InputOnChangeFormat
                    );
                    input.onChange(validDate);

                    const day = format(date as Date, "dd");
                    const month = format(date as Date, "MM");
                    const year = format(date as Date, "yyyy");

                    props.setDay && props.setDay(day);
                    props.setMonth && props.setMonth(month);
                    props.setYear && props.setYear(year);
                  } else {
                    input.onChange(null);
                  }
                }}
                onCalendarClose={() => {
                  input.value &&
                    props.onBlurCapture?.({
                      target: {
                        name: input.name,
                        value: input.value,
                      },
                    });
                }}
                yearDropdownItemNumber={5}
                minDate={props.min}
                maxDate={props.max}
                showYearDropdown
                dropdownMode="select"
                locale={supportedLang[language as ObjectKey].locale}
              />
              {props.optionalText && (
                <span className="text-xs text-slate-500">
                  {props.optionalText}
                </span>
              )}
            </>
          );
        }}
      </Field>
    </div>
  );
};

Datepicker.displayName = "Datepicker";
