import React, { createContext, useContext, useState } from "react";
import Toast from "./Toast";
import { ToastConfigProps, ToastContextProps } from "./ToastTypes";

const ToastContext = createContext<ToastContextProps>({} as ToastContextProps);

export function ToastProvider({ children }: { children: React.ReactNode }) {
  const [toastState, setToastState] = useState(false);
  const [toastConfig, setToastConfig] = useState<ToastConfigProps>({});

  const showToast = (config: ToastConfigProps) => {
    setToastConfig(config);
    setToastState(true);
  };

  return (
    <ToastContext.Provider value={{ showToast }}>
      {children}
      <Toast
        toastConfig={toastConfig}
        toastState={toastState}
        setToastState={setToastState}
      />
    </ToastContext.Provider>
  );
}

export const useToastConfig = () => {
  return useContext(ToastContext);
};
