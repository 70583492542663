import { chevronDown, chevronUp } from "../../../../icons/chevronUpDown";
import { useTranslation } from 'react-i18next';

type AccordionProps = {
  keyToMap: string;
  title: string;
  expanded: boolean;
  onToggle: () => void;
  labels: string[];
  selectedFilters: string[];
  onFilterSelect: (filter: string) => void;
  onFilterRemove: (filter: string) => void;
  isFirstAccordion: boolean;
}

  const Accordion: React.FC<AccordionProps> = ({
    keyToMap,
    title,
    expanded,
    onToggle,
    labels,
    isFirstAccordion,
    selectedFilters,
    onFilterSelect,
    onFilterRemove,
  }) => {
    
    const { t, i18n } = useTranslation();
    const handleCheckboxChange = (label: string, isChecked: boolean) => {
      if (isChecked) {
        onFilterSelect(label);
      } else {
        onFilterRemove(label);
      }
    };
  
    return (
      <div className={`mt-${isFirstAccordion ? '4' : '0'} ${isFirstAccordion ? 'lg:border-t-0 border-b' : 'border-b'} border-gray-300`}>
        <div
          className={`flex h-12 lg:px-0 px-4 justify-between items-center rounded cursor-pointer ${expanded ? 'border-b border-gray-300' : ''}`} 
          onClick={onToggle}
          aria-expanded={expanded}  
          aria-controls={`content-${keyToMap}`}
          id={`header-${keyToMap}`}
        >
          <div className="font-semibold">
            {title} {selectedFilters.length > 0 && `(${selectedFilters.length})`}
          </div>
          <div className="transform transition-transform">
            {/* Up and down chevron */}
            {expanded ? chevronUp : chevronDown}
          </div>
        </div>
        {expanded && (
          <div className="lg:px-0 px-4" id={`content-${keyToMap}`} role="region" aria-labelledby={`header-${keyToMap}`}>
            {/* Placeholder for filter options */}
            {labels.map((label: string) => {
              const translationKey = `lifestage.${label}`;
              const hasExactTranslation = i18n.exists(translationKey);
              const translatedLabel = hasExactTranslation ? t(translationKey) : label;

              // Only render if translatedLabel is not an empty string
              if (translatedLabel) {
                return (
                  <div key={label} className="flex items-center my-2">
                    <input
                      type="checkbox"
                      value={label}
                      checked={selectedFilters.includes(label)}
                      onChange={(e) => handleCheckboxChange(label, e.target.checked)}
                      className="form-checkbox mr-2 my-2 h-4 w-4 border-brand-color-library-blue-500 rounded text-brand-color-library-500"
                      id={`checkbox-${label}`}
                    />
                    <label htmlFor={`checkbox-${label}`}>
                      {keyToMap === "lifestage" || keyToMap === "healthCategory" || keyToMap === "productForm" ? translatedLabel : label}
                    </label>
                  </div>
                );
              }
              return null;
            })}
          </div>
        )}
    </div>
  );
};

  export default Accordion;