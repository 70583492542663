import React from "react";
import MyPetsHeading from "../../../../components/MyPetsHeading/MyPetsHeading";
import AddPet from "../../../../components/AddPets/AddPet";

const NoPetsWrapper = () => {
  return (
    <div data-testid="no_pets_div" className="w-full px-5 pt-8 lg:px-0 lg:pt-0">
      <MyPetsHeading />
      <AddPet />
    </div>
  );
};

export default NoPetsWrapper;
