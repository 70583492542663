export const heartIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    role="img"
    aria-label="heart icon"
    >
      <g aria-hidden="true">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.9999 34.1229L33.0907 21.0321C36.377 17.7458 36.377 12.4177 33.0907 9.13138C29.8044 5.84508 24.4762 5.84508 21.19 9.13137L20.0002 10.3211L18.8098 9.13074C15.5235 5.84444 10.1954 5.84444 6.90906 9.13074C3.64963 12.3902 3.62298 17.6582 6.82912 20.9505C6.83919 20.9608 6.8493 20.9711 6.85943 20.9814C6.87589 20.9982 6.89244 21.0149 6.90906 21.0315L8.09985 22.2229L19.9999 34.1229Z"
          fill="#C4132A"/>
      </g>
  </svg>
);
