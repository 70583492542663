import * as Modal from "../../../../../components/Modal";
import HideDeleteModal from "../../../../../components/ShareHideDelete/HideDeleteModal";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export function DeleteModalWrapper() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Modal.Root
      open={true}
      onOpenChange={(isOpen) => {
        if (!isOpen) {
          navigate(-1);
        }
      }}
    >
      <Modal.SideModal title={t("myPets.hideDeletePet.deleteTitle")}>
        <HideDeleteModal action="delete" />
      </Modal.SideModal>
    </Modal.Root>
  );
}
