import axios from "axios";
import { API_URLS } from "../constants/apiUrls";

export async function setPetStatus({
  petId,
  petStatusId,
  petRemovalReason
}: {
  petId:string,
  petStatusId:number,
  petRemovalReason?:string
}) {
  const options = {petData:[{
    petId,
    petStatusId,
    petRemovalReason
  }]};

  const formData = new FormData();
  formData.append("data", JSON.stringify(options));
 

  return await axios
  .put(`${process.env.REACT_APP_PET_API_URL}${API_URLS.PETS}`, formData,{
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
  .then((apiResponse) => apiResponse);
}

