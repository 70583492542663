import PetDetails from "../PetDetails";
import MyPetsSwiper from "../../../../components/MyPetsSwiper";
import { PetType } from "../../../../../types";

const PetsWrapper = ({ petsData }: { petsData: Array<PetType> }) => {
  return (
    <div>
      <MyPetsSwiper petsData={petsData} />
      <PetDetails />
    </div>
  );
};

export default PetsWrapper;
