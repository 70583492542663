import axios from "axios";
import { parse } from "date-fns";
import { API_URLS, GENERATE_MILESTONES_PATH } from "../constants/apiUrls";

interface ServerMilestone {
  petLifeStageId: number;
  petMilestoneHelperText: string;
  petMilestoneId: number;
  petMilestoneMasterId: string;
  petMilestoneName: string;
  petTypeId: number;
  suggestedDates: {
    from: string | null;
    to: string | null;
  };
  suggestedWeeks: string;
  completedDate?: string;
}

function formatMilestone(m: ServerMilestone) {
  return {
    ...m,
    suggestedDates: {
      from: m.suggestedDates.from
        ? parseDateString(m.suggestedDates.from)
        : null,
      to: m.suggestedDates.to ? parseDateString(m.suggestedDates.to) : null,
    },
    completedDate: m.completedDate ? parseDateString(m.completedDate) : null,
  };
}

function parseDateString(dateString: string) {
  return parse(dateString, "yyyy-MM-dd", new Date());
}
interface MilestonesResponse {
  data: ServerMilestone[];
  metadata: {
    previousPage: {
      lifeStageId: number;
      milestoneCount: number;
    } | null;
    nextPage: {
      lifeStageId: number;
      milestoneCount: number;
    } | null;
  };
}

async function fetchMilestones({
  petId,
  lifestageId,
}: {
  petId: string;
  lifestageId: number;
}) {
  return await axios
    .get<MilestonesResponse>(
      `${process.env.REACT_APP_PET_API_URL || ""}${
        API_URLS.PETS
      }${GENERATE_MILESTONES_PATH(String(petId), String(lifestageId))}`
    )
    .then((res) => {
      if (res.status !== 200) {
        throw new Error("Error fetching milestones");
      }
      return {
        milestones: res?.data?.data.map(formatMilestone),
        nextLifestage: res?.data?.metadata?.nextPage?.lifeStageId,
        previousLifestage: res?.data?.metadata?.previousPage?.lifeStageId,
      };
    });
}

export type Milestones = Awaited<ReturnType<typeof fetchAllMilestones>>;
export type MilestonesData = Milestones[number];

export async function fetchAllMilestones({
  petId,
  initialLifestageId,
}: {
  petId: string;
  initialLifestageId: number;
}) {
  const maxLifeStages = 3;
  const milestones = [];
  let previousLifestage: number | undefined = initialLifestageId;
  let nextLifestage: number | undefined = initialLifestageId;
  let fetchedLifestages = 0;
  const startOrder = 3;
  let order = startOrder;

  // Fetch milestones for previous lifestages first
  while (previousLifestage && fetchedLifestages < maxLifeStages) {
    order--;

    const {
      milestones: fetchedMilestones,
      // @ts-expect-error for some reason the build compiler can't infer this type so commenting it here until we find a fix
      previousLifestage: prev,
      nextLifestage: next,
    } = await fetchMilestones({ petId, lifestageId: previousLifestage });
    milestones.push({
      lifestageId: previousLifestage,
      milestones: fetchedMilestones,
      order: order,
    });
    previousLifestage = prev;

    // If this is the first fetch, set next lifestage to the next one to the initial
    // to avoid fetching the same lifestage twice
    if (fetchedLifestages === 0) {
      nextLifestage = next;
    }

    fetchedLifestages++;
  }

  order = startOrder;
  // If not enough lifestages were fetched, fetch milestones for next lifestages
  while (nextLifestage && fetchedLifestages < maxLifeStages) {
    const { milestones: fetchedMilestones, nextLifestage: next } =
      await fetchMilestones({ petId, lifestageId: nextLifestage });
    milestones.push({
      lifestageId: nextLifestage,
      milestones: fetchedMilestones,
      order: order,
    });
    nextLifestage = next;
    order++;
    fetchedLifestages++;
  }

  // Sort milestones by order to determine the order
  milestones.sort((a, b) => a.order - b.order);

  // Assign indexes from 1 to 3 based on the sorted order
  const indexedMilestones = milestones.map((milestone, index) => ({
    index: index + 1,
    ...milestone,
  }));

  return indexedMilestones;
}
