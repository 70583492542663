export const filterIconWhite = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <g aria-hidden="true">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.9998 3L3.88632 3.00609C3.14599 3.0865 2.73751 3.94581 3.18283 4.57668L8.9998 12.817V20.5C8.9998 21.3592 10.0117 21.8183 10.6583 21.2526L14.6583 17.7526L14.7455 17.6662C14.9083 17.484 14.9998 17.2472 14.9998 17V12.815L20.8168 4.57668C21.2844 3.91427 20.8106 3 19.9998 3H3.9998ZM18.0698 5L13.1828 11.9233L13.1181 12.0283C13.0407 12.1728 12.9998 12.3348 12.9998 12.5V16.545L10.9998 18.295V12.5L10.9922 12.3769C10.972 12.2142 10.912 12.0583 10.8168 11.9233L5.9288 5H18.0698Z"
        fill="white"
      />
    </g>
  </svg>
);
