export interface ILANG {
  EN_US: string;
  PT_BR: string;
  FR_CA: string;
  EN_CA: string;
  FR_FR: string;
  EN_GB: string;
}
export const LANG: ILANG = {
  EN_US: "en-us",
  PT_BR: "pt-br",
  FR_CA: "fr-ca",
  EN_CA: "en-ca",
  FR_FR: "fr-fr",
  EN_GB: "en-gb"
};

export const PREFIX = [LANG.PT_BR,LANG.FR_CA,LANG.FR_FR]
export const DDMMYYYY_FORMAT = [LANG.PT_BR];
