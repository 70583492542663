import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { EditBasicInfo } from "../../../components/EditBasicInfo/EditBasicInfo";
import * as Modal from "../../../components/Modal";
import { ACCOUNT_SETTINGS, ROUTES } from "../../../constants";

export function EditBasicInfoModal() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Modal.Root
      open={true}
      onOpenChange={(isOpen) => {
        if (!isOpen) {
          navigate(ROUTES.ROOT + ROUTES.ACCOUNT_SETTINGS);
        }
      }}
    >
      <Modal.SideModal title={t(ACCOUNT_SETTINGS.EDIT_BASIC_INFO)}>
        <EditBasicInfo />
      </Modal.SideModal>
    </Modal.Root>
  );
}
