import * as Modal from "../../../components/Modal";
import { PetForm } from "../../../components/PetForm";
import { ROUTES_PATH } from "../../../constants";
import useSearchPersistantNavigate from "../../../hooks/useSearchPersistantNavigate";
import { useTranslation } from "react-i18next";

export function AddPetModal() {
  const { t } = useTranslation();
  const searchPersistantNavigate = useSearchPersistantNavigate();

  return (
    <Modal.Root
      open={true}
      onOpenChange={(isOpen) => {
        if (!isOpen) {
          searchPersistantNavigate(`${ROUTES_PATH.MY_PETS}`);
        } else {
          searchPersistantNavigate(`${ROUTES_PATH.ADD_PET}`);
        }
      }}
    >
      <Modal.SideModal title={t("myPets.addEditPet.addPetTitle")}>
        <PetForm />
      </Modal.SideModal>
    </Modal.Root>
  );
}
