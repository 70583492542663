import axios from "axios";
import { useApi } from "utils";
import { API_URLS } from "../constants/apiUrls";

export const FetchRecommendedArticles = () => {
    const { exec: fetchArticleList, data } = useApi(async () => {
        return axios
            .get(`${process.env.REACT_APP_ARTICLE_API_URL}${API_URLS.ARTICLES}`)
    });
    return { fetchArticleList, data }
};
