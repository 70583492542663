export class DateValidatorErrorList {
  private errors: {
    day?: string[];
    month?: string[];
    year?: string[];
    date?: string[];
  } = {};

  constructor(errors?: DateValidatorErrorList["errors"]) {
    if (errors) {
      this.errors = errors;
    }
  }

  get all() {
    return Object.values(this.errors).flat();
  }

  get day() {
    return this.errors.day;
  }

  get month() {
    return this.errors.month;
  }

  get year() {
    return this.errors.year;
  }

  get date() {
    return this.errors.date;
  }

  public addError(key: keyof DateValidatorErrorList["errors"], error: string) {
    if (!this.errors[key]) {
      this.errors[key] = [];
    }
    (this.errors[key] as string[]).push(error);
  }

  public addDayError(error: string) {
    this.addError("day", error);
  }

  public addMonthError(error: string) {
    this.addError("month", error);
  }

  public addYearError(error: string) {
    this.addError("year", error);
  }

  public addDateError(error: string) {
    this.addError("date", error);
  }

  public hasErrors() {
    return this.all.length > 0;
  }

  public hasDayErrors() {
    return !!this.errors.day;
  }

  public hasMonthErrors() {
    return !!this.errors.month;
  }

  public hasYearErrors() {
    return !!this.errors.year;
  }

  public hasDateErrors() {
    return !!this.errors.date;
  }
}
