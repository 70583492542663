export const RememberedPetIconLarge = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="none"
  >
    <g id="Remembered">
      <g id="Vector">
        <path
          d="M1.45512 24.5C2.2587 24.5 2.90164 23.846 2.98985 23.0473C3.71267 16.502 9.26232 11.41 16.0001 11.41C22.7379 11.41 28.2875 16.502 29.0104 23.0473C29.0986 23.846 29.7415 24.5 30.5451 24.5C31.3487 24.5 32.007 23.8464 31.9349 23.0461C31.1997 14.8938 24.3429 8.5 16.0001 8.5C7.65728 8.5 0.800546 14.8938 0.0653575 23.0461C-0.00681854 23.8464 0.651539 24.5 1.45512 24.5Z"
          fill="#66676B"
        />
        <path
          d="M7.27517 24.5C8.07875 24.5 8.71501 23.842 8.87439 23.0544C9.54612 19.7349 12.4847 17.2301 16.0001 17.2301C19.5155 17.2301 22.4541 19.7349 23.1258 23.0544C23.2852 23.842 23.9215 24.5 24.725 24.5C25.5286 24.5 26.191 23.8448 26.0776 23.0493C25.3741 18.1125 21.1322 14.3201 16.0001 14.3201C10.868 14.3201 6.62616 18.1125 5.92261 23.0493C5.80924 23.8448 6.47159 24.5 7.27517 24.5Z"
          fill="#66676B"
        />
        <path
          d="M12.2183 23.1906C12.7592 21.6227 14.2456 20.5 16.0001 20.5C17.7546 20.5 19.241 21.6227 19.7819 23.1906C20.022 23.8867 19.4032 24.5 18.6668 24.5H13.3334C12.5971 24.5 11.9782 23.8867 12.2183 23.1906Z"
          fill="#66676B"
        />
      </g>
    </g>
  </svg>
);
