import React from "react";

export const PetLoader = () => {
  return (
    <div className="mx-2.5 grid h-screen place-items-center">
      <img
        alt="loader"
        src={process.env.REACT_APP_URL + "/gigya/petloader.gif"}
        className="w-28"
      />
    </div>
  );
};

export default PetLoader;
