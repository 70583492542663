import { PetType } from "@types";
import { useNavigate } from 'react-router-dom';
import { SwiperSlide } from "./SwiperSlide";
import { useEffect, useMemo, useRef, useState } from "react";
import { AddPetButton, MyPetsHeader, defaultImage } from "./common";

interface SwiperFreeModeContainerProps {
  petsList: Array<PetType>;
  isMobile: boolean;
  petId?: string;
}

// Define the type for the Swiper container

export const SwiperFreeModeContainer = (
  swiperProps: SwiperFreeModeContainerProps
) => {
  const { petsList, isMobile, petId } = swiperProps;
  const [selectedPet, setSelectedPet] = useState<PetType | null>(null);
  const sixPetsCriteria = petsList.length <= 6;
  const showAddPetButtonInHeader = petsList.length >= 6;
  const selectedPetRef = useRef<null | HTMLDivElement>(null);
  const navigate = useNavigate();

  const setActivePet = useMemo(()=>{
    let selectedPet = petsList[0];
    if (petId) {
      const petIndex = petsList.findIndex((pet) => pet.petId === petId);
      selectedPet = petsList[petIndex] || petsList[0];
    }
    setSelectedPet(selectedPet);
  },[petsList,petId])

  useEffect(() => {
    selectedPetRef.current?.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
  },[setActivePet])

  const onSlideClick = (pet: PetType) => {
    setSelectedPet(pet);
    navigate(`/mypets/?petId=${pet.petId}`);
  }


  return (
    <div className="flex flex-col" data-testid={"SwiperFreeModeContainer"}>
      {!isMobile && (
        <MyPetsHeader
          showAddPetButtonInHeader={showAddPetButtonInHeader}
          isMobile={isMobile}
          sixPetsCriteria={sixPetsCriteria}
        />
      )}
      <div className="flex p-1">
        <div className="no-scrollbar flex h-20 flex-nowrap items-center overflow-x-auto">
          {petsList.map((pet: PetType) => {
            return (
              <div
                key={pet.petId}
                ref={selectedPet?.petId === pet.petId ? selectedPetRef : null}
                role="button"
                className={`${!isMobile && `mx-2`}`}
              >
                <SwiperSlide
                  petObj={pet}
                  isSelected={selectedPet?.petId === pet.petId}
                  onSlideClick={() => onSlideClick(pet)}
                  defaultImage={defaultImage}
                  isMobile={isMobile}
                  sixPetsCriteria={sixPetsCriteria}
                />
              </div>
            );
          })}
          {(!showAddPetButtonInHeader || isMobile)  && (
            <AddPetButton isMobile={isMobile} showAddPetButtonInHeader={showAddPetButtonInHeader}/>
          )}
        </div>
      </div>
    </div>
  );
};
