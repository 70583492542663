export const RemembredPetsIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
  >
     <g id="Remembred pets Icon" aria-hidden="true">
    <path
      d="M1.00045 16.5C1.55291 16.5 1.99493 16.0504 2.05557 15.5013C2.55251 11.0014 6.3679 7.50064 11.0001 7.50064C15.6324 7.50064 19.4477 11.0014 19.9447 15.5013C20.0053 16.0504 20.4473 16.5 20.9998 16.5C21.5523 16.5 22.0049 16.0507 21.9553 15.5004C21.4498 9.89577 16.7358 5.5 11.0001 5.5C5.26443 5.5 0.550429 9.89577 0.0449867 15.5004C-0.00463434 16.0507 0.447986 16.5 1.00045 16.5Z"
      fill="#0054A4"
    />
    <path
      d="M5.00173 16.5C5.5542 16.5 5.99162 16.0476 6.10119 15.5061C6.56301 13.224 8.58329 11.5019 11.0001 11.5019C13.417 11.5019 15.4372 13.224 15.8991 15.5061C16.0086 16.0476 16.4461 16.5 16.9985 16.5C17.551 16.5 18.0063 16.0496 17.9284 15.5026C17.4447 12.1086 14.5285 9.50129 11.0001 9.50129C7.47179 9.50129 4.55554 12.1086 4.07185 15.5026C3.99391 16.0496 4.44927 16.5 5.00173 16.5Z"
      fill="#0054A4"
    />
    <path
      d="M8.40016 15.5998C8.77202 14.5218 9.79391 13.75 11.0001 13.75C12.2063 13.75 13.2282 14.5218 13.6001 15.5998C13.7652 16.0784 13.3397 16.5 12.8335 16.5H9.16679C8.66053 16.5 8.23507 16.0784 8.40016 15.5998Z"
      fill="#0054A4"
    />
    </g>
  </svg>
);
