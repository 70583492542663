import * as Modal from "../../../components/Modal";
import { PetForm } from "../../../components/PetForm";
import { ROUTES_PATH } from "../../../constants";
import useSearchPersistantNavigate from "../../../hooks/useSearchPersistantNavigate";

export function EditPetModal() {
  const searchPersistantNavigate = useSearchPersistantNavigate();

  return (
    <Modal.Root
      open={true}
      onOpenChange={(isOpen) => {
        if (!isOpen) {
          searchPersistantNavigate(`${ROUTES_PATH.MY_PETS}`);
        }
      }}
    >
      <Modal.SideModal title="Edit Pet">
        <PetForm />
      </Modal.SideModal>
    </Modal.Root>
  );
}
