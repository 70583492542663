import React from "react";

export function ChevronRight(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 26"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.29999 17.1L9.69999 18.5L15.7 12.5L9.69999 6.5L8.29999 7.9L12.9 12.5L8.29999 17.1Z"
      />
    </svg>
  );
}
