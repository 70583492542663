import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as Modal from "../../../components/Modal";
import { useToastConfig } from "../../../components/toast";
import screensets from "../../../config/gigya/screensets";
import {
  ACCOUNT_SETTINGS,
  AUTHENTICATION_STATUS,
  ROUTES,
  ROUTES_PATH
} from "../../../constants";
import useRequireAuth from "../../../hooks/useRequireAuth";
import { useSubscribeToGigyaEvent } from "../../../hooks/useSubscribeToGigyaEvent";
import gigya from "../../../lib/gigya";
import { ChangePassword } from "../../../constants/changePassword";
import { useTranslation } from "react-i18next";

export function ChangePasswordModal() {
  const auth = useRequireAuth();
  const { showToast } = useToastConfig();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleCloseModal = useCallback(function handleCloseModal() {
    showToast({
      description: t(ACCOUNT_SETTINGS.CHANGES_SAVED),
      type: "success",
    });
    navigate(ROUTES_PATH.ACCOUNT_SETTINGS);
  }, []);

  useSubscribeToGigyaEvent({
    type: "onSubmitSuccess",
    screenName: screensets.CHANGEPASSWORD.startScreen,
    callback: handleCloseModal,
  });

  useEffect(() => {
    if (auth?.status === AUTHENTICATION_STATUS.AUTHENTICATED) {
      gigya?.accounts.showScreenSet(screensets.CHANGEPASSWORD);
    }
  }, [auth]);

  return (
    <Modal.Root
      open={true}
      onOpenChange={(isOpen) => {
        if (!isOpen) {
          navigate(ROUTES.ROOT + ROUTES.ACCOUNT_SETTINGS);
        }
      }}
    >
      <Modal.SideModal title={t(ChangePassword.CHANGE_PASSWORD)}>
        <div id={screensets.CHANGEPASSWORD.containerID} />
      </Modal.SideModal>
    </Modal.Root>
  );
}
