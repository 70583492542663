import React from "react";
import { IconCat, IconDog } from "ui-2";
import { MY_PETS } from "../../constants";
import { useTranslation } from "react-i18next";

const MyPetsHeading = () => {
  const { t } = useTranslation();

  return (
    <div className="mb-8 hidden items-center gap-2 lg:flex">
      <div className="flex flex-row">
        <div className="flex h-8 w-8 items-center justify-center">
          {IconDog}
        </div>
        <div className="flex h-8 w-8 items-center justify-center">
          {IconCat}
        </div>
      </div>
      <div className="headline3">{t(MY_PETS.CAROUSAL_HEADER)}</div>
    </div>
  );
};

export default MyPetsHeading;
