import axios from "axios";
import { API_URLS } from "../constants/apiUrls";

export const setFoodSelect = async (
    petId: string,
    petFoodTypeId: number,
    petFoodTypeInfo: string
  ) => {
    const formattedData = {
      petData: [
        {
          petId,
          petFoodTypeId,
          petFoodTypeInfo: petFoodTypeInfo || 'N/A',
        },
      ],
    };

  const formData = new FormData();
  formData.append("data", JSON.stringify(formattedData));

  try {
    const response = await axios.put(`${process.env.REACT_APP_PET_API_URL}${API_URLS.PETS}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    
    if (response.data.serviceStatus.code === 200) {
      return response.data;
    } else {
      throw new Error('Error saving selected food.');
    }
  } catch (error) {
    console.error('Error saving selected food:', error);
    throw error;
  }
};