import { useEffect } from "react";

export function useSubscribeToGigyaEvent({
  type,
  screenName,
  callback,
}: {
  type: string;
  screenName?: string;
  callback: (event: unknown) => void;
}) {
  useEffect(() => {
    function handleGigyaEvent(event: Record<string, unknown>) {
      if (event.type === type) {
        if (typeof screenName === "string" && event.screenName !== screenName) {
          return;
        }
        callback(event);
      }
    }

    window.customGigyaEvents?.addEventListener(type, handleGigyaEvent);

    return () => {
      window.customGigyaEvents?.removeEventListener(type, handleGigyaEvent);
    };
  }, [type, callback]);
}
