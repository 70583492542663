declare global {
  interface Window {
    dataLayer: Array<DataLayerEvent>;
  }
}

interface DataLayerEvent {
  event: string;
  user_type?: string;
  method?: string;
  action: string;
  click_text?: string;
}

export function datalayerPush(...dataLayers: Partial<DataLayerEvent>[]) {
  const defaultLayerAttributes = {
    user_type: "",
    method: "",
    event: "",
    action: "",
  };
  const mergedDataLayers = dataLayers.reduce((acc, dataLayer) => {
    return { ...acc, ...dataLayer };
  }, defaultLayerAttributes);

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(mergedDataLayers);
}
