export const API_URLS = {
  PARENT_PROFILE: "/v1/parent/profile",
  PET_CONFIG: "/v1/pets/references",
  PETS: "/v1/pets",
  LIFESTAGES: "/lifestages",
  MILESTONES: "/milestones",
  MILESTONE: "/milestone",
  ARTICLES: "/v1/recommended-articles",
  HILLS_PRODUCTS: process.env.REACT_APP_MOCK_HILLS_PRODUCTS_URL || "/v1/pxm",
  PARENT_NOTIFICATIONS: "/v1/parent/notification",
};

export const GENERATE_MILESTONES_PATH = (
  petId: string,
  lifestageId: string
) => {
  return `/${petId}${API_URLS.LIFESTAGES}/${lifestageId}${API_URLS.MILESTONES}`;
};
