import { CloseIcon } from "../../../../icons/closeIcon";

// Chip.tsx
type ChipProps = {
  label: string;
  onClose: () => void;
}

const Chip: React.FC<ChipProps> = ({ label, onClose }) => {

  
  return (
    <div
      className="inline-flex items-center justify-start bg-brand-color-library-blue-200 rounded-full px-4 py-1 h-11"
      role="listbox"
    >
      <span>{label}</span>
      <button onClick={onClose} className="ml-2" aria-label={`Remove ${label}`}> 
        <CloseIcon className="h-4 w-4"/>
      </button>
    </div>
  );
};

export default Chip;