import { PetType } from "../../../../../types";
import KebabMenu from "./components/KebabMenu";

const PetsNameHeadingWrapper = ({ petData,setLoader }: { petData: PetType,setLoader:(show:boolean)=>void }) => {
  return (
    <div className="headline4 ml-5 lg:headline1 mb-2 flex items-center">
      <span>{petData.petName}</span>
      <KebabMenu petData={petData} setLoader={setLoader}/>
    </div>
  );
};

export default PetsNameHeadingWrapper;
