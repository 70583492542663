export const chevronUp = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    role="img"
    aria-label="filter tool tip"
  >
    <g aria-hidden="true">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.6 15.7L18 14.3L12 8.29995L6 14.3L7.4 15.7L12 11.1L16.6 15.7Z"
      fill="#0054A4"
    />
    </g>
  </svg>
);

export const chevronDown = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    role="img"
    aria-label="filter tool tip"
  >
    <g aria-hidden="true">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.6 8.30005L18 9.70005L12 15.7L6 9.70005L7.4 8.30005L12 12.9L16.6 8.30005Z"
      fill="#0054A4"
    />
    </g>
  </svg>
);
