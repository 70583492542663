export const ChangePassword = {
  CURRENT_PASSWORD: "changePassword.currentPassword",
  FORGOT_PASSWORD_TEXT: "changePassword.forgotPasswordText",
  NEW_PASSWORD: "changePassword.newPassword",
  CHOOSE_A_NEW_PASSWORD: "changePassword.chooseNewPassword",
  CONFIRM_PASSWORD: "changePassword.confirmPassword",
  CHANGE_PASSWORD: "changePassword.changePassword",
  CANCEL: "common.cancel",
  PASSWORD_CHANGED: "changePassword.passwordChanged",
  PASSWORD_CHANGED_DESCRIPTION: "changePassword.passwordChangedDesc",
  PASSWORD_CHANGE_ERROR: "changePassword.passwordChangeErr",
  PASSWORD_CHANGE_ERROR_DESCRIPTION: "changePassword.passwordChangeErrDesc",
  PASSWORD_RESET: "changePassword.passwordReset",
  PASSWORD_RESET_LINK_SENT: "changePassword.passwordResetLinkSent",
};
