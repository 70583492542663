import { useEffect, useRef } from "react";
import gigya from "../../lib/gigya";
import SCREENSETS from "../../config/gigya/screensets";

const SignIn = () => {
  const restrictLoadScreen = useRef(false);

  const loadScreen = () => {
    gigya?.accounts.showScreenSet({
      ...SCREENSETS.LOGIN,
    });
  };
  useEffect(() => {
    if (gigya !== undefined && !restrictLoadScreen.current) {
      restrictLoadScreen.current = true;
      loadScreen();
    }
  }, []);
  return (
    <div className="drop-shadow-xl">
      <div className="divide-y">
        <div id="gigya-user-login"  data-testid="gigya-user-login"></div>
      </div>
    </div>
  );
};

export default SignIn;
