export const ROUTES = {
  ROOT: "/",
  ACCOUNT_HOME: "account-home",
  MY_PETS: "mypets",
  ADD_PET: "add-pet",
  EDIT_PET: "edit-pet",
  HIDE_PET: "hide-pet",
  DELETE_PET: "delete-pet",
  MILESTONE: "milestone/:petId/:lifestageId",
  ERROR_PAGE: "error",
  AUTH_WIDGET_DEMO: "auth-widget-demo",
  ACCOUNT_SETTINGS: "account-settings",
  COMMUNICATION_PREFERENCES: "communication-preferences",
  EDIT_BASIC_INFO: "edit-basic-information",
  CHANGE_PASSWORD: "change-password",
  FOOD_TYPE: "food-type",
  REMEMBERED_PETS: "remembered-pets",
};

export const ROUTES_PATH = {
  ACCOUNT_HOME: ROUTES.ROOT + ROUTES.ACCOUNT_HOME,
  MY_PETS: ROUTES.ROOT + ROUTES.MY_PETS,
  ADD_PET: `${ROUTES.ROOT}${ROUTES.MY_PETS}/${ROUTES.ADD_PET}`,
  EDIT_PET: `${ROUTES.ROOT}${ROUTES.MY_PETS}/${ROUTES.EDIT_PET}`,
  HIDE_PET: `${ROUTES.ROOT}${ROUTES.MY_PETS}/${ROUTES.HIDE_PET}`,
  DELETE_PET: `${ROUTES.ROOT}${ROUTES.MY_PETS}/${ROUTES.DELETE_PET}`,
  MILESTONE: ROUTES.ROOT + "milestone",
  AUTH_WIDGET_DEMO: ROUTES.ROOT + ROUTES.AUTH_WIDGET_DEMO,
  ERROR_PAGE: ROUTES.ROOT + ROUTES.ERROR_PAGE,
  ACCOUNT_SETTINGS: ROUTES.ROOT + ROUTES.ACCOUNT_SETTINGS,
  COMMUNICATION_PREFERENCES: `${ROUTES.ROOT}${ROUTES.ACCOUNT_SETTINGS}/${ROUTES.COMMUNICATION_PREFERENCES}`,
  EDIT_BASIC_INFO: `${ROUTES.ROOT}${ROUTES.ACCOUNT_SETTINGS}/${ROUTES.EDIT_BASIC_INFO}`,
  CHANGE_PASSWORD: `${ROUTES.ROOT}${ROUTES.ACCOUNT_SETTINGS}/${ROUTES.CHANGE_PASSWORD}`,
  FOOD_TYPE: `${ROUTES.ROOT}/${ROUTES.FOOD_TYPE}`,
};

//Component to be developed
export const BREADCRUMB_CONFIG = {};
