import * as Dialog from "@radix-ui/react-dialog";
import React from "react";
import { classnames } from "utils";
import { ModalCloseIcon } from "../../icons";

export interface SideModalProps {
  title: string;
  children: React.ReactNode;
  onClose?: () => void;
  contentClasses?: string;
  headerClasses?: string;
}

export const SideModal = ({
  title,
  children,
  contentClasses,
  headerClasses,
  onClose,
}: SideModalProps) => {
  return (
    <Dialog.Portal>
      {/* Using z-index "200" bellow to cover the AEM navigation bar */}
      <Dialog.Overlay
        onClick={onClose}
        className="fixed top-0 left-0 z-[200] flex h-screen w-screen justify-end overflow-auto lg:bg-brand-color-library-gray-900/60"
      />
      <Dialog.Content
        className={classnames(
          "grid_hphv2 fixed top-0 right-0 z-[200] h-full w-full grid-rows-[min-content_auto] bg-white md:h-screen lg:w-3/5",
          contentClasses
        )}
      >
        <div
          className={classnames(
            "headline5 lg:headline4 flex w-full items-center justify-center bg-brand-color-library-blue-800 px-5 py-4 lg:py-6 lg:px-10",
            headerClasses
          )}
        >
          <Dialog.Title className="text-white">{title}</Dialog.Title>
          <Dialog.Close
            className="absolute top-4 right-5 h-6 w-6 cursor-pointer fill-white lg:right-10 lg:top-6 lg:h-8 lg:w-8"
            onClick={onClose}
          >
            {ModalCloseIcon}
          </Dialog.Close>
        </div>
        <div className="overflow-scroll">{children}</div>
      </Dialog.Content>
    </Dialog.Portal>
  );
};

export default SideModal;
