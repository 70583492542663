import { useEffect, useState } from "react";
import AsyncSelect from "react-select/async";
import { FOOD_BRANDS, IFOOD_BRANDS } from '../../../constants/foodSelectOtherBrands';
import { useTranslation } from "react-i18next";
import { Field } from "react-final-form";
import { ControlProps, OptionProps } from "react-select";

export default function BrandSelection({
    setFoodTypeDetails, selectedRegion
}: {
    setFoodTypeDetails: (value: IFOOD_BRANDS | null) => void;
    selectedRegion: string | undefined;
}) {
    const [foodBrands, setFoodBrands] = useState<IFOOD_BRANDS[]>([]);
    const { t } = useTranslation();

    useEffect(() => {
        if (FOOD_BRANDS && selectedRegion) {
            setFoodBrands(
                FOOD_BRANDS.filter((brand: IFOOD_BRANDS) =>
                    brand.region.includes(selectedRegion)
                )
            );
        }
    }, [selectedRegion]);

    const customStyles = {
        dropdownIndicator: (base: object) => ({
            ...base,
            border: "none",
            padding: "5px",
            alignItems: "center",
        }),
        indicatorSeparator: (base: object) => ({
            ...base,
            display: "none",
        }),
        control: (base: object, state: ControlProps) => ({
            ...base,
            borderRadius: "4px",
            outline: state.isFocused ? "solid" : "none",
            outlineWidth: state.isFocused ? "1px" : "",
            outlineColor: state.isFocused ? "#0054A4" : "",
            outlineOffset: state.isFocused ? "1px" : "",
        }),
        option: (base: object, state: OptionProps) => ({
            ...base,
            color: "#111111",
            backgroundColor: state.isSelected ? "#DDEAFE" : "#FFFFFF",
            border: state.isFocused
                ? state.isSelected
                    ? ""
                    : "1px solid #0054A4"
                : "",
            "&:hover": {
                backgroundColor: state.isSelected ? "#DDEAFE" : "#EEF5FF",
            },
        }),
    };

    const loadOptions = (inputValue: string, callback: (options: IFOOD_BRANDS[]) => void) => {
        const filteredOptions = foodBrands.filter(brand =>
            brand.brandName.toLowerCase().includes(inputValue.toLowerCase())
        );
        callback(filteredOptions);
    };

    return (
        <div>
            <Field
                name="petFoodTypeDetails"
                id="petFoodTypeDetails"
            >
                {({ input, meta, ...rest }) => (
                    <>
                        {foodBrands?.length ? (
                            <AsyncSelect
                                {...input}
                                {...rest}
                                placeholder={t("addEditPet.searchBrand")}
                                inputId="brandSelection"
                                onChange={(e) => {
                                    input.onChange(e);
                                    setFoodTypeDetails(e);
                                }}
                                cacheOptions
                                loadOptions={loadOptions}
                                isClearable={true}
                                defaultOptions={foodBrands}
                                classNamePrefix="brands"
                                styles={customStyles}
                                getOptionValue={(item: IFOOD_BRANDS) => item.brandName.toString()}
                                getOptionLabel={(item: IFOOD_BRANDS) => item.brandName.toString()}
                                theme={(theme) => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary: "#C6C7CC",
                                    },
                                })}
                                aria-label={t("addEditPet.brandSelectionAriaLabel") || "Brand selection"}
                                aria-describedby="brand-selection-description"
                            />
                        ) : (
                            <div className="mb-2 text-xs text-brand-error">
                                {t("myPets.foodTypeModal.petFoodCommonError")}
                            </div>
                        )}
                        <div id="brand-selection-description" className="sr-only">
                            {t("addEditPet.brandSelectionDescription")}
                        </div>
                        <div>
                            {meta.error && meta.submitFailed && (
                                <div className="mb-2 text-xs text-brand-error">
                                    {t("myPets.foodTypeModal.petFoodBrandError")}
                                </div>
                            )}
                        </div>
                    </>
                )}
            </Field>
        </div>
    );
}
