import axios from "axios";
import { useApi } from "utils";
import { API_URLS } from "../constants/apiUrls";
import { Auth, useAuth } from "../hooks/use-auth";

export const setNotification = (notificationObj:{notificationTypeId:number,showNotification:boolean}) => {
    const auth: Auth = useAuth();

    const headersObj = { 
      locale : window.hph_locale,
      UID : auth?.user?.UID, 
      notificationtypeid:notificationObj.notificationTypeId,
      show_notification:notificationObj.showNotification.toString()
    }

    const { exec: setNotificationData } = useApi(async () => {
      axios.post(`${process.env.REACT_APP_PROFILE_API_URL}${API_URLS.PARENT_NOTIFICATIONS}`,{},
      {headers: headersObj}).then(
        (apiResponse) => {
          if (apiResponse?.data?.serviceStatus?.code === 200) {
            return apiResponse.data;
          }
        }
      )
    })

    return { setNotificationData }
}