import { useState } from "react";
import { Form } from "react-final-form";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, FormControl } from "ui-2";
import { RememberedBlackIcon } from "../../icons/RememberedBlackIcon";
import * as Modal from "../Modal";
import CondolenceModal from "./CondolenceModal";
import { useToastConfig } from "../toast";
import { usePromise } from "utils";
import { setPetStatus } from "../../api-calls/useUpdatePetStatus";
import { PET_HIDE_REASONS, ROUTES_PATH } from "../../constants/index";
import { alertBigSize } from "../../icons/alertBigSize";
import { useMyPets } from "../../hooks/useMyPet";
import { MY_PETS } from "../../constants/myPets";

interface FormValues {
  reasonType?: string;
}

interface FormErrors {
  reasonType?: string;
}

export default function HideDeleteModal({ action }: { action: string }) {
  const navigate = useNavigate();
  const [touched, setTouched] = useState<boolean>(false);
  const [isCondolenceModalOpen, setCondolenceModalOpen] =
    useState<boolean>(false);
  const { t } = useTranslation();
  const { state } = useLocation();
  const { showToast } = useToastConfig();
  const [{ isPending, isError }, { exec: setPetStatusData }] =
    usePromise(setPetStatus);
  const { refetchPetList } = useMyPets();

  const petName = state?.petName;
  const rawDescriptionText = t("myPets.hideDeletePet.description");
  const rawDeleteDescriptionText = t(
    "myPets.hideDeletePet.deletePrimaryText"
  ).split(/<Pet Name>/);
  const hideDescriptionParts = rawDescriptionText.split(
    /<Pet Name>|{icon}|RememberedPetsText/
  );

  const onSubmit = async (values: FormValues): Promise<FormErrors | void> => {
    setTouched(true);
    if (!values.reasonType) {
      return { reasonType: `${t("myPets.hideDeletePet.selectError")}` };
    } else {
      const removalReason =
        values?.reasonType == "1"
          ? PET_HIDE_REASONS.DECEASED
          : PET_HIDE_REASONS.REHOMED;
      try {
        const response = await setPetStatusData({
          petId: state?.petId,
          petStatusId: action == "hide" ? 2 : 3,
          petRemovalReason: removalReason,
        });
        if (response?.status === 200) {
          if (values?.reasonType == "1") {
            setCondolenceModalOpen(true);
          } else if (!isPending || !isError) {
            refetchPetList();
            navigate(ROUTES_PATH.MY_PETS);
            showToast({
              title: "Success",
              type: "success",
              description:
                action == "hide"
                  ? `${t("myPets.hideDeletePet.petHiddenSuccessfully")}`
                  : `${t("myPets.hideDeletePet.petDeletedSuccessfully")}`,
            });
          }
        } else {
          showToast({
            title: "Error",
            type: "error",
            description: `${t("myPets.hideDeletePet.savingError")}`,
          });
        }
      } catch (err) {
        showToast({
          title: "Error",
          type: "error",
          description: `${t("myPets.hideDeletePet.savingError")}`,
        });
      }
    }
  };

  const onCancel = () => {
    navigate(-1);
  };

  const handleModalClose = () => {
    refetchPetList();
    navigate(ROUTES_PATH.MY_PETS);
    showToast({
      title: "Success",
      type: "success",
      description:
        action === "hide"
          ? `${t("myPets.hideDeletePet.petHiddenSuccessfully")}`
          : `${t("myPets.hideDeletePet.petDeletedSuccessfully")}`,
    });
  };

  const validate = (values: FormValues): FormErrors => {
    const errors: FormErrors = {};
    if (!values.reasonType) {
      errors.reasonType = `${t("myPets.hideDeletePet.selectError")}`;
    }
    return errors;
  };

  return (
    <>
      <div>
        <Form<FormValues>
          onSubmit={onSubmit}
          validate={validate}
          render={({ handleSubmit, submitFailed, errors, submitting }) => (
            <form onSubmit={handleSubmit}>
              {action === "delete" ? (
                <div className="mt-8 flex justify-center">{alertBigSize}</div>
              ) : (
                ""
              )}
              <div className="flex lg:justify-center">
                <div
                  className="mx-5 mt-8 mb-10 overflow-hidden lg:w-1/2"
                  data-testid="pet-type"
                >
                  <div>
                    {action === "delete" ? (
                      <>
                        <span>{rawDeleteDescriptionText[0]}</span>
                        <strong>{petName}</strong>
                        <span>{rawDeleteDescriptionText[1]}</span>
                      </>
                    ) : (
                      <>
                        <span>{hideDescriptionParts[0]}</span>
                        <strong>{petName}</strong>
                        <span>{hideDescriptionParts[1]}</span>
                        <span className="flex inline-flex items-baseline gap-2">
                          <span className="relative top-1.5">
                            {RememberedBlackIcon}
                          </span>
                        </span>
                        <strong>
                          &nbsp;
                          {t(MY_PETS.REMEMBRED_PETS.REMEMBEREDPETSHEADING)}
                        </strong>
                        <span>{hideDescriptionParts[2]}</span>
                      </>
                    )}
                    <br />
                    <br />

                    {action === "delete" ? (
                      <div>{t("myPets.hideDeletePet.deleteSubText")}</div>
                    ) : (
                      <div>{t("myPets.hideDeletePet.subText")}</div>
                    )}
                  </div>
                  <div className="mt-5">
                    <div className="label2 mb-2">
                      {t("myPets.hideDeletePet.petRemovalReason")}
                      <span className="text-brand-color-library-red-800">
                        {" "}
                        *
                      </span>
                    </div>
                    <div className="flex flex-col gap-6 lg:flex-row">
                      <FormControl
                        data-testid="reason-type-deceased"
                        key={`deceased_type`}
                        fieldProps={{
                          name: "reasonType",
                          component: "input",
                          value: "1",
                        }}
                        type="radio"
                        label={t("myPets.hideDeletePet.deceased")}
                        variant="default"
                      />
                      <FormControl
                        data-testid="reason-type-rehomed"
                        key={`rehomed_type`}
                        fieldProps={{
                          name: "reasonType",
                          component: "input",
                          value: "2",
                        }}
                        type="radio"
                        label={t("myPets.hideDeletePet.rehomed")}
                        variant="default"
                      />
                    </div>
                  </div>
                  {(touched || submitFailed) && errors?.reasonType && (
                    <div className="mt-2 text-red-800">{errors.reasonType}</div>
                  )}
                </div>
              </div>

              <Modal.Footer className="md:flex-row-reverse md:justify-start">
                {action === "hide" ? (
                  <Button
                    type="submit"
                    data-testid="save-button"
                    disabled={submitting || isPending}
                  >
                    {t("myPets.hideDeletePet.save")}
                  </Button>
                ) : (
                  <Button
                    type="submit"
                    variant="dangerOutlined"
                    data-testid="save-button"
                    disabled={submitting || isPending}
                  >
                    {t("myPets.hideDeletePet.deleteSave")}
                  </Button>
                )}
                <Button
                  type="button"
                  data-testid="cancel-button"
                  onClick={onCancel}
                  variant="blueText"
                >
                  {t("myPets.hideDeletePet.cancel")}
                </Button>
              </Modal.Footer>
            </form>
          )}
        />
      </div>
      <Modal.Root
        open={isCondolenceModalOpen}
        onOpenChange={(isOpen: boolean) => {
          if (!isOpen) {
            handleModalClose();
          }
        }}
      >
        <Modal.SideModal title={t("myPets.hideDeletePet.condolenceTitle")}>
          <CondolenceModal onClose={handleModalClose} />
        </Modal.SideModal>
      </Modal.Root>
    </>
  );
}
