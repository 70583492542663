const swiperDesktopConfig = {
  slidesPerView: 6,
  slidesPerGroup: 6,
  containerMessage: 'Pets Caraousal',
  enabled: true,
  itemRoleDescriptionMessage: 'Pet',
  lastSlideMessage: 'Last Pet',
  firstSlideMessage: 'First Pet',
  paginationBulletMessage: 'Go to slide',
};

export { swiperDesktopConfig };
