export function PencilIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      aria-hidden="true"
      {...props}
    >
      <path
        id="icon-edit"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 7.6574C20 5.63725 18.3625 4 16.3426 4L16.0948 4.00899L15.8496 4.03592C15.0404 4.15049 14.3164 4.51155 13.7566 5.07138L6.4108 12.4172L6.24853 12.5908C5.88443 13.0081 5.62321 13.5071 5.48816 14.0465L4 20L9.95311 18.5119L10.1818 18.4464C10.7091 18.2757 11.1898 17.9822 11.5828 17.5892L18.9286 10.2434L19.0816 10.0809C19.6716 9.41407 20 8.55268 20 7.6574ZM16.22 6.11369L16.3454 6.10883L16.4941 6.11592C17.277 6.19094 17.8911 6.85231 17.8911 7.6574C17.8911 8.06792 17.728 8.46167 17.4374 8.75225L16.5683 9.62134L14.3786 7.43164L15.2477 6.56255L15.3645 6.45708C15.6075 6.25895 15.9058 6.13864 16.22 6.11369ZM12.8875 8.92281L15.0772 11.1125L10.626 15.5637L10.0785 13.9214L8.43626 13.374L12.8875 8.92281ZM7.36357 15.2393L6.89782 17.1011L8.76045 16.6361L8.4109 15.588L7.36357 15.2393Z"
      />
    </svg>
  );
}
