import React from 'react';
import { HideIcon,DeleteIcon,CloseIcon,ShowIcon } from '../../../../../icons';
import { useTranslation } from 'react-i18next';
import { useMyPets } from '../../.././../../hooks/useMyPet';

interface MobileKebabModalProps {
    show: boolean;
    onClose: () => void;
    hideShowtext: string;
    handleShowHideClick: () => void;
    handleDeleteClick: () => void;
}

const MobileKebabModal: React.FC<MobileKebabModalProps> = ({ show, onClose,hideShowtext,handleShowHideClick, handleDeleteClick }) => {
    const { t } = useTranslation();
    const {
        isViewRememberedPets
      } = useMyPets();
    const hideShowIcon = isViewRememberedPets ? ShowIcon : HideIcon;
    if (!show) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-end z-[200]">
            <div className="bg-white rounded-t-lg w-full p-6 relative">
                <button onClick={onClose} className="absolute top-4 right-4">
                    <CloseIcon className='h-6 w-6'/>
                </button>
                <div className="flex flex-col mt-4">
                    <div className="flex label2 mx-6 my-4 justify-center items-center text-brand-primary cursor-pointer" onClick={handleShowHideClick}>
                        <span className='mr-2'>{hideShowIcon}</span>
                        {hideShowtext}
                    </div>
                    <div className="flex label2 mx-6 my-4 justify-center items-center text-brand-error cursor-pointer" onClick={handleDeleteClick}>
                        <span className='mr-2'>{DeleteIcon}</span>
                        {t("myPets.showHidePopup.deletePet")}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MobileKebabModal;