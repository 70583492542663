import React from "react";
import { xCloseBtn } from "../../icons/xCloseBtn";
import { Button, FormControl } from "ui-2";
import { Form } from "react-final-form";

interface rebateObjType {
  id: string;
  header: string;
  body: string;
  ctaOneText: string;
  ctaTwoText?: string;
  ctaOneAction: (isChecked: boolean) => void;
  ctaTwoAction?: () => void;
  closeCtaAction: (isChecked: boolean) => void;
  position?: string;
  showCheckbox?: boolean;
  checkboxLabel?: string;
  ctaOneStyle?: string;
  ctaTwoStyle?: string
  icon?: JSX.Element
}

interface rebateProps {
  rebateObj: rebateObjType;
}

const RebatePopup: React.FC<rebateProps> = ({ rebateObj }) => {
  const [isChecked, setIsChecked] = React.useState(false);
  const onCheckboxClick = (isChecked: boolean) => {
    setIsChecked(isChecked);
  }
  const isButtonsOnLeft = rebateObj.position === "left";

  return (
    <div
      className={`flex max-w-2xl flex-row items-start rounded-lg bg-[#EEF5FF] lg:p-6 p-4 ${isButtonsOnLeft ? "justify-between" : "justify-start"
        }`}
    >
      <div className="flex flex-col">
        <div className="flex justify-between">
          <div className="label2 lg:label1" data-testid="header">{rebateObj.header}</div>

          <div
            onClick={() => {
              rebateObj.closeCtaAction(isChecked);
            }}
           className="cursor-pointer"
            data-testid="close-button"
          >
            {xCloseBtn}
          </div>
        </div>
        <div className="body3 lg:body2 py-2 lg:py-4" data-testid="body">{rebateObj.body}</div>
        <div
          className={`font-semibold text-brand-primary lg:flex-row ${!rebateObj.ctaTwoText ? `items-start` : `justify-between`
            } ${isButtonsOnLeft ? "order-2" : ""}`}
          data-testid="button-container"
        >
          {rebateObj.showCheckbox && (
            <Form
              onSubmit={() => console.log("Submit")
              }
              mutators={{

              }}
              render={() => (
                <FormControl
                  fieldProps={{
                    name: "data.communicationPrefEmail",
                    component: "input",
                  }}
                  checked={isChecked}
                  type="checkbox"
                  label={rebateObj.checkboxLabel}
                  onChange={(e) => onCheckboxClick(e.target.checked)}
                />
              )}
            />
          )}
        </div>
        <div className="flex justify-end mt-4 lg:mt-6">
          <Button
            variant="blueOutlined"
            size="lg"
            type="button"
            onClick={() => {
              rebateObj.ctaOneAction(isChecked);
            }}
            classes="mr-4 bg-white label2 lg:label1"
          >
            {rebateObj.ctaOneText}
          </Button>
          {rebateObj.ctaTwoText ? (

            <Button
              variant="primary"
              size="lg"
              type="button"
              onClick={() => { rebateObj.ctaTwoAction && rebateObj.ctaTwoAction() }}
              classes="lg:px-8 label2 lg:label1"
            >
              {rebateObj.ctaTwoText}
            </Button>
          ) : (
            ""
          )}
        </div>
      </div>

    </div>
  );
};

export default RebatePopup;