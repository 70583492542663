import useRequireAuth from "../../../../hooks/useRequireAuth";
import WelcomeUser from "../../../../components/WelcomeUser";

const WelcomeUserWrapper = () => {
  const { user } = useRequireAuth();
  const profileInformation = user?.profile;

  return <WelcomeUser user={profileInformation || null} />;
};

export default WelcomeUserWrapper;
