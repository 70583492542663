import FoodTypeModal from "../../components/FoodTypeModal/FoodTypeModal";
import { useTranslation } from "react-i18next";
import { DietInfoCardProps } from "../../../types";
import { MY_PETS } from "../../constants";
import { editIcon2 } from "../../icons";
import { FoodQuizLink } from "./FoodQuizLink";
import * as Modal from "../../components/Modal";
import GTM_ACTIONS from "../../config/gtmActions";
import { datalayerPush } from "../../lib/gtm"; 

const DietInfoCard = ({ DietData, onFoodSelectionSaved, onClose, children }: { 
  DietData: DietInfoCardProps; 
  onFoodSelectionSaved: () => void; 
  onClose: () => void; 
  children?: React.ReactNode
}) => {
  const { t } = useTranslation();
  const { petFoodTypeId, hillsProductImage, hillsProductDesc, petName, petFoodTypeInfo, petId, petTypeId } =
    DietData;

    const handleSelectFoodClick = () => {
      datalayerPush(GTM_ACTIONS.MY_PETS_DIET_SELECT_FOOD_CLICK);  
    };

    return (
      <div aria-label="diet-info-card" className="flex w-full flex-col">
        <Modal.Root>
          {petFoodTypeId === 1 && hillsProductImage && hillsProductDesc ? (
            <div className="flex flex-col justify-around pb-2">
              <div className="flex flex-row items-center justify-between self-stretch"> {/* Title for option 1 */}
                <div className="label2 lg:headline5">{t(MY_PETS.PROFILE.LABEL2)}</div>
              </div>
              <div className="flex w-full flex-col gap-2 self-stretch rounded-lg p-4 shadow-dp2 lg:gap-4 lg:p-6">
                <div className="flex flex-row gap-2">
                  <div className="flex h-[90px] w-[75px] flex-col items-center justify-center lg:h-[98px] lg:w-[82px]">
                    <img
                      aria-label="hills-product"
                      src={hillsProductImage}
                      alt="petData"
                      className="w-[75px] lg:w-[82px]"
                    />
                  </div>
                  <div className="flex flex-col justify-center gap-1 lg:gap-2 flex-grow">
                    <div aria-label="hills-product-name" className="body2">
                      {hillsProductDesc}
                    </div>
                  </div>
                  <div className="h-[24px] w-[24px] cursor-pointer fill-[#0054A4] flex-shrink-0">
                    <Modal.Trigger className="h-[24px] w-[24px] cursor-pointer fill-[#0054A4]">
                      {editIcon2}
                    </Modal.Trigger>
                  </div>
                </div>
                <div className="mt-4">{children}</div>
              </div>
              <div
                data-testid="food-quiz-question"
                className="label2 lg:headline5 mt-8 w-full text-left"
              >
                {t(MY_PETS.DIET.FOOD_QUIZ_QUESTION, { petName })}
              </div>
              <div className="mt-4 w-full sm:w-auto">
                <FoodQuizLink petType={DietData.petType} />
              </div>
            </div>
          ) : petFoodTypeId === 2 || petFoodTypeId === 3 ? (
            <div className="flex flex-col justify-around pb-2">
              <div className="flex flex-row justify-between items-center">
                <div className="label2 lg:headline5">
                  {t(MY_PETS.PROFILE.LABEL2)}
                </div>
                <Modal.Trigger className="flex items-center cursor-pointer fill-[#0054A4]">
                  <div className="flex items-center">
                    <div className="h-[24px] w-[24px]">{editIcon2}</div>
                    <span className="ml-2 text-brand-primary font-bold">
                      {t("myPets.diet.edit")}
                    </span>
                  </div>
                </Modal.Trigger>
              </div>
              <div className="flex w-full flex-col gap-2 self-stretch mt-4 lg:gap-4"> {/* Modified block for option 2 and 3 */}
                <div className="flex flex-row gap-2">
                  <div className="flex flex-col justify-center gap-1 lg:gap-2 flex-grow">
                    <div aria-label="custom-food" className="body2">
                      {petFoodTypeInfo}
                    </div>
                  </div>
                </div>
              </div>
              <div
                data-testid="food-quiz-question"
                className="label2 lg:headline5 mt-8 w-full text-left"
              >
                {t(MY_PETS.DIET.FOOD_QUIZ_QUESTION, { petName })}
              </div>
              <div className="mt-4 w-full sm:w-auto">
                <FoodQuizLink petType={DietData.petType} />
              </div>
            </div>
          ) : (
            <div aria-label="diet-info-card" className="flex w-full flex-col">
              <div className="flex flex-row items-center justify-between self-stretch">
                <div className="label2 lg:headline5">{t(MY_PETS.PROFILE.LABEL2)}</div>
              </div>
              <div className="flex flex-col items-center sm:items-start">
                <Modal.Trigger 
                  data-testid="select-food" 
                  className="mt-4 h-[42px] w-full justify-center rounded border border-brand-primary text-lg font-semibold text-brand-primary sm:w-auto px-4"
                  onClick={handleSelectFoodClick}
                >
                  {t(MY_PETS.DIET.SELECT_FOOD)}
                </Modal.Trigger>
                <div 
                  data-testid="food-quiz-question" 
                  className="label2 lg:headline5 mt-8 w-full text-left"
                >
                  {t(MY_PETS.DIET.FOOD_QUIZ_QUESTION, { petName })}
                </div>
                <div className="mt-4 w-full sm:w-auto">
                  <FoodQuizLink petType={DietData.petType} />
                </div>
              </div>
            </div>
)}
          <FoodTypeModal
            petName={petName}
            petId={petId}
            petTypeId={petTypeId !== null ? petTypeId : undefined}
            onFoodSelectionSaved={onFoodSelectionSaved}
            onClose={onClose}
          />
        </Modal.Root>
      </div>
    );
};

export default DietInfoCard;
