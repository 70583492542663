export const filterIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="inherit"
    role="img"
    aria-label="filter tool tip"
  >
    <g aria-hidden="true">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.00034 2.25L2.91523 2.25457C2.35998 2.31488 2.05362 2.95936 2.38761 3.43251L6.75034 9.61275V15.375C6.75034 16.0194 7.50928 16.3638 7.99421 15.9394L10.9942 13.3144L11.0597 13.2497C11.1817 13.113 11.2503 12.9354 11.2503 12.75V9.61125L15.6131 3.43251C15.9638 2.9357 15.6084 2.25 15.0003 2.25H3.00034ZM13.5528 3.75L9.88761 8.94249L9.83903 9.02119C9.78103 9.12961 9.75034 9.25109 9.75034 9.375V12.4088L8.25034 13.7213V9.375L8.24463 9.28269C8.2295 9.16066 8.18452 9.04372 8.11306 8.94249L4.44709 3.75H13.5528Z"
      fill="inherit"
    />
    </g>
  </svg>
);
