import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { PetType } from "../../../../../types";
import { useFoodSelectionHandler } from "../../../../components/FoodTypeModal/FoodSelectionModal/Util/foodSelectionHandler";
import FoodTypeModal from "../../../../components/FoodTypeModal/FoodTypeModal";
import DietInfoCardWrapper from "../DietInfoCardWrapper/DietInfoCardWrapper";

function Diet({ pet, refetchPet }: { pet: PetType; refetchPet: () => void }) {
  const [, setSearchParams] = useSearchParams();
  const [isFoodTypeModalOpen, setIsFoodTypeModalOpen] = useState(false);
  const { handleFoodSelectionSaved } = useFoodSelectionHandler({
    petId: pet.petId,
    setSearchParams,
    refetchPet,
    setIsFoodTypeModalOpen,
  });

  return (
    <>
      <DietInfoCardWrapper
        petData={pet}
        onFoodSelectionSaved={handleFoodSelectionSaved}
        onClose={() => setIsFoodTypeModalOpen(false)}
      />
      {isFoodTypeModalOpen && (
        <FoodTypeModal
          petName={pet.petName}
          petId={pet.petId}
          petTypeId={pet.petTypeId}
          onFoodSelectionSaved={handleFoodSelectionSaved}
          onClose={() => setIsFoodTypeModalOpen(false)}
        />
      )}
    </>
  );
}

export default Diet;
