/* eslint-disable @typescript-eslint/no-namespace */
/* eslint-disable @typescript-eslint/no-explicit-any */
declare global {
  interface Window {
    gigya: any;
    auth: any;
  }
}
const gigya = window.gigya;

export default gigya;
