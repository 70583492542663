import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import Cropper, { Area } from "react-easy-crop";
import { useTranslation } from "react-i18next";
import { Button } from "ui";
import { ZoomIn } from "../../icons/ZoomIn";
import { ZoomOut } from "../../icons/ZoomOut";

import { Modal } from "./..//Modal";
import {getCroppedImg} from "./cropImage";

export function PhotoCropper({
  open,
  setOpen,
  setCroppedImage,
  image,
  cropShape,
  aspect,
  title,
}: {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  image?: string;
  cropShape?: "round" | "rect";
  aspect?: number;
  title: string;
  setCroppedImage: (croppedImage: string, coordinates?: Area) => void;
}) {
  const { t } = useTranslation();
  const [croppedCordinates, setCroppedCordinates] = useState({ x: 0, y: 0 });
  const [zoomLevel, setZoomLevel] = useState<number>(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>();

  const onCropChange = (crop: { x: number; y: number }) => {
    setCroppedCordinates(crop);
  };

  const onCropComplete = (_: Area, croppedAreaPixels: Area) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const onZoomChange = (zoom: number) => {
    setZoomLevel(zoom);
  };

  const showCroppedImage = useCallback(async () => {
    const croppedImage = await getCroppedImg(
      image as string,
      croppedAreaPixels as Area,
      0
    );
    setCroppedImage(croppedImage as string, croppedAreaPixels);
  }, [croppedAreaPixels]);

  useEffect(() => {
    open && setZoomLevel(1);
  }, [open]);

  return (
    <Modal
      modalConfig={{
        title,
        btnConfig: [],
        body: (
          <div className="px-2.5 md:w-full">
            <div>{}</div>
            <div className="relative h-64 md:h-80">
              <Cropper
                image={image}
                crop={croppedCordinates}
                zoom={zoomLevel}
                aspect={aspect}
                cropShape={cropShape}
                showGrid={false}
                onCropChange={onCropChange}
                onCropComplete={onCropComplete}
                onZoomChange={onZoomChange}
                objectFit="contain"
              />
            </div>
            <div className="mt-10 flex flex-col items-center">
              <div className="flex flex-row w-full justify-center">
                <ZoomOut className="mr-2"/>
                <div className="w-4/5">
                  <label
                    htmlFor="zoom"
                    className="mb-2 block hidden text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Zoom
                  </label>
                  <input
                    id="zoom"
                    type="range"
                    value={zoomLevel}
                    className="range-sm mb-6 h-1 w-full cursor-pointer appearance-none rounded-lg bg-gray-200 accent-gray-500 dark:bg-gray-700 "
                    onChange={(e) => onZoomChange(+e?.target?.value)}
                    min={1}
                    max={3}
                    step={0.1}
                  />
                </div>
                <ZoomIn className="ml-2"/>
              </div>
              <Button
                type="button"
                mode="contained"
                variant="primary"
                classes="mt-10 lg:mt-2 w-80 py-4 font-bold text-xl px-4"
                onClick={showCroppedImage}
                aria-label="continue button"
              >
                {t("common.done")}
              </Button>
            </div>
          </div>
        ),
      }}
      dialogState={open}
      setDialog={setOpen}
    />
  );
}

export default PhotoCropper;
