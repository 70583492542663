import { useEffect, useState } from "react";
import { PetType } from "../../../types";
import { SwiperMyPets } from "./SwiperMyPets";
import { SwiperFreeModeContainer } from "./SwiperFreeModeContainer";
import { debounce } from "utils";
import { useSearchParams } from "react-router-dom";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { useMyPets } from "@hooks/useMyPet";
import { SwiperRememberedPets } from "./SwiperRememberedPets";

interface MyPetsSwiperProps {
  petsData: Array<PetType>;
}

const MyPetsSwiper: React.FC<MyPetsSwiperProps> = ({
  petsData,
}): JSX.Element => {
  const [isMobile, setIsMobile] = useState(false);
  const petsMobileViewLimit = 6;
  const [searchParams] = useSearchParams();
  const petId = searchParams.get("petId") || "";
  const { isViewRememberedPets } = useMyPets();

  useEffect(() => {
    const handleResize = debounce(() => {
      // Perform actions on window resize
      setIsMobile(window.innerWidth < 1024);
    }, 200);

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div>
      {petsData.length <= petsMobileViewLimit || isMobile ? (
        <SwiperFreeModeContainer
          petsList={petsData}
          isMobile={isMobile}
          petId={petId}
        />
      ) : isViewRememberedPets ? (
        <SwiperRememberedPets
          petsList={petsData}
          isMobile={isMobile}
          petId={petId}
        />
      ) : (
        <SwiperMyPets petsList={petsData} isMobile={isMobile} petId={petId} />
      )}
    </div>
  );
};

export default MyPetsSwiper;
