export function UncompletedMilestoneBadge(
  props: React.SVGProps<SVGSVGElement>
) {
  return (
    <svg
      width="52"
      height="56"
      viewBox="0 0 52 56"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.6611 2.87061L15.906 2.15296L13.0284 7.17654L7.27323 8.61184V14.3531L2.95686 17.9413L5.11504 22.9649L2.95686 28.7061L7.27323 32.2944L7.84025 36.8196L0 47.6705H9.80195L13.1819 56.0001L20.3746 43.9373L21.6611 43.7769L25.9775 46.6475L30.2938 43.7769L31.629 43.9434L38.8181 56.0001L42.1981 47.6705H52L44.1214 36.7665L44.6817 32.2944L48.9981 28.7061L46.8399 22.9649L48.9981 17.9413L44.6817 14.3531L43.9623 8.61184L38.9266 7.17654L36.049 2.15296L30.2938 2.87061L25.9775 0L21.6611 2.87061Z"
      />
    </svg>
  );
}
