import React from 'react';
import { chevronDown, chevronUp } from '../../../../icons/chevronUpDown';

interface FeedingGuideTitleProps {
  title: string;
  isOpen?: boolean;
  toggleAccordion?: () => void;
}

const FeedingGuideTitle: React.FC<FeedingGuideTitleProps> = ({ title, isOpen, toggleAccordion }) => {
    return (
      <>
        <div className="flex justify-between items-center cursor-pointer" onClick={toggleAccordion}>
          <h3 className="text-md text-brand-gray-light font-semibold">{title}</h3>
          <div>{isOpen ? chevronUp : chevronDown}</div>
        </div>
        {isOpen && ( // Conditionally render the gray line based on isOpen
        <hr className="mt-4 mb-6 border-t border-gray-300"/>
      )}
      </>
    );
  };

export default FeedingGuideTitle;