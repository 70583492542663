export const ACCOUNT_SETTINGS = {
  HEADING: "accountHome.accountSettings.accountSettings",
  DESCRIPTION: "accountHome.accountSettings.description",
  PASSWORD_CHANGE: "accountHome.accountSettings.changePassword",
  COMMUNICATION_PREFERENCES:
    "accountHome.accountSettings.communicationPreferences",
  PROFILE_PICTURE: "accountSettings.profilePicture",
  EDIT_IMAGE: "accountSettings.editImage",
  BASIC_INFORMATION: "accountSettings.basicInformation",
  EDIT: "accountSettings.edit",
  NAME: "accountSettings.name",
  EMAIL: "accountSettings.email",
  CHANGE_COMMUNICATION_PREFERENCES:
    "accountSettings.changeCommunicationPreferences",
  SECURITY: "accountSettings.security",
  PASSWORD: "accountSettings.password",
  LOG_OUT: "accountSettings.logOut",
  DELETE_MY_ACCOUNT: "accountSettings.deleteMyAccount",
  EDIT_BASIC_INFO: "accountSettings.editBasicInfo",
  FIRST_NAME: "accountSettings.firstName",
  LAST_NAME: "accountSettings.lastName",
  SAVE: "accountSettings.save",
  CHANGES_SAVED: "accountSettings.changesSaved",
};
