export default {
  LOGIN: {
    screenSet: 'HPH-RegistrationLogin',
    startScreen: 'gigya-login-screen',
    containerID: 'gigya-user-login',
  },
  REGISTER: {
    screenSet: 'HPH-RegistrationLogin',
    startScreen: 'create-account-email',
    containerID: '',
  },
  FORGOTPASSWORD: {
    screenSet: 'HPH-RegistrationLogin',
    startScreen: 'gigya-forgot-password-screen',
    containerID: '',
  },
  EDITPROFILE: {
    screenSet: 'HPH-ProfileUpdate',
    startScreen: 'gigya-update-profile-screen',
    containerID: 'gigya-update-profile-screen',
  },
  CHANGEPASSWORD: {
    screenSet: 'HPH-ProfileUpdate',
    startScreen: 'gigya-change-password-screen',
    containerID: 'gigya-change-password-screen',
  },
  PUPPY:{
    screenSet: 'HPH-RegistrationLogin',
    startScreen: 'hph-puppy-lightbox-gigya-email',
    containerID: 'gigya-puppy-screen',
  },
  KITTEN:{
    screenSet: 'HPH-RegistrationLogin',
    startScreen: 'hph-kitten-lightbox-gigya-email',
    containerID: 'gigya-kitten-screen',
  },
  WEIGHT:{
    screenSet: 'HPH-RegistrationLogin',
    startScreen: 'hph-weight-lightbox-gigya-email',
    containerID: 'gigya-weight-screen',
  },
  PROMOTIONAL:{
    screenSet: 'HPH-RegistrationLogin',
    startScreen: 'hph-promotional-lightbox-gigya-email',
    containerID: 'gigya-promotional-screen',
  },
  COMMUNICATION: {
    screenSet: 'HPH-ProfileUpdate',
    startScreen: 'gigya-communication-screen',
    containerID: 'gigya-communication-container',
  },

};