export const KebabSvg = (
<svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="More" clipPath="url(#clip0_18410_15426)">
<path id="Vector" d="M16.0002 11.1668C17.4668 11.1668 18.6668 9.96683 18.6668 8.50016C18.6668 7.0335 17.4668 5.8335 16.0002 5.8335C14.5335 5.8335 13.3335 7.0335 13.3335 8.50016C13.3335 9.96683 14.5335 11.1668 16.0002 11.1668ZM16.0002 13.8335C14.5335 13.8335 13.3335 15.0335 13.3335 16.5002C13.3335 17.9668 14.5335 19.1668 16.0002 19.1668C17.4668 19.1668 18.6668 17.9668 18.6668 16.5002C18.6668 15.0335 17.4668 13.8335 16.0002 13.8335ZM16.0002 21.8335C14.5335 21.8335 13.3335 23.0335 13.3335 24.5002C13.3335 25.9668 14.5335 27.1668 16.0002 27.1668C17.4668 27.1668 18.6668 25.9668 18.6668 24.5002C18.6668 23.0335 17.4668 21.8335 16.0002 21.8335Z" fill="#44464B"/>
</g>
<defs>
<clipPath id="clip0_18410_15426">
<rect width="32" height="32" fill="white" transform="translate(0 0.5)"/>
</clipPath>
</defs>
</svg>
)