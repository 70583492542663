export const ADD_EDIT_PET = {
  CHANGE_PHOTO: "common.changePhoto",
  UPLOAD_PHOTO: "common.uploadPhoto",
  BREED: "addEditPet.breed",
  SEARCH_BREED: "addEditPet.searchBreed",
  AGE: "addEditPet.age",
  PETS_NAME: "addEditPet.petsName",
  PETS_NICKNAME: "addEditPet.petsNickname",
  PETS_TYPE: "addEditPet.petsType",
  PETS_GENDER: "addEditPet.petsGender",
  PETS_BIRTHDAY: "addEditPet.petsBirthday",
  PETS_AGE: "addEditPet.petsAge",
  PETS_AGE_YEAR: "addEditPet.petsAgeYear",
  PETS_AGE_MONTH: "addEditPet.petsAgeMonth",
  YEAR: "addEditPet.year",
  YEARS: "addEditPet.years",
  MONTH: "addEditPet.month",
  DAY: "addEditPet.day",
  PET: "addEditPet.pet",
  ACTUAL_DATE: "addEditPet.actualDate",
  APPROXIMATE_DATE: "addEditPet.approximateDate",
  PETS_ADOPTION_DATE: "addEditPet.petsAdoptionDate",
  PETS_LIFE_STAGE: "addEditPet.petsLifeStage",
  PETS_VET: "addEditPet.petsVet",
  PETS_FOOD: "addEditPet.petsFood",
  PETS_FOOD_NOT_SELECTED_TEXT: "addEditPet.petsFoodNotSelectedText",
  HOME_MADE: "addEditPet.Homemade",
  OTHER_BRAND: "addEditPet.Other Brand",
  OTHER_BRAND_NOT_SELECTED_TEXT: "addEditPet.otherBrandNotSelectedText",
  S_VET: "addEditPet.sVet",
  COMMON_ERROR_WARNING: "addEditPet.commonErrorWarning",
  HIDE_PET: "addEditPet.hidePet",
  REMOVE_PET: "addEditPet.removePet",
  UNHIDE_PET: "addEditPet.unhidePet",
  FOOD_QUIZ: "addEditPet.foodQuiz",
  UPDATE: "common.update",
  SAVE: "common.save",
  CANCEL: "common.cancel",
  REMOVED: "common.removed",
  ADDED: "common.added",
  FOR_DOGS: "addEditPet.forDogs",
  FOR_CATS: "addEditPet.forCats",
  DOG: "addEditPet.Dog",
  CAT: "addEditPet.Cat",
  MALE: "addEditPet.Male",
  FEMALE: "addEditPet.Female",
  PET_AGE_MAX_WARNING_1: "addEditPet.petAgeMaxWarning1",
  PET_AGE_MAX_WARNING_2: "addEditPet.petAgeMaxWarning2",
  PET_AGE_MAX_WARNING_3: "addEditPet.petAgeMaxWarning3",
  PET_SIZE_ERROR: "addEditPet.petSizeError",
  PET_BREED_ERROR: "addEditPet.petBreedError",
  PET_FOOD_BRAND_ERROR: "addEditPet.petFoodBrandError",
  PET_FOOD_COMMON_ERROR: "common.updateFailed",
  OPTIONAL_TEXT: "addEditPet.optionalText",
  PET_SIZE: "addEditPet.petsSize",
  NOT_SELECTED_TEXT: "addEditPet.notSelectedText",
  SMALL_WEIGHT_RANGE_DOG: "myPets.addEditPet.smallWeightRangeDog",
  MEDIUM_WEIGHT_RANGE_DOG: "myPets.addEditPet.mediumWeightRangeDog",
  LARGE_WEIGHT_RANGE_DOG: "myPets.addEditPet.largeWeightRangeDog",
  SMALL_WEIGHT_RANGE_CAT: "myPets.addEditPet.smallWeightRangeCat",
  MEDIUM_WEIGHT_RANGE_CAT: "myPets.addEditPet.mediumWeightRangeCat",
  LARGE_WEIGHT_RANGE_CAT: "myPets.addEditPet.largeWeightRangeCat",
  PETS_ADOPTION_DATE_SHOULD_BE_GREATER : "addEditPet.birthdayGreaterThanAdoptDate",
  PET_ADDED_MESSAGE: "addEditPet.petAddedMessage",
  PET_ADDING_ERROR: "addEditPet.error"
};
