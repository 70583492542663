import axios from "axios";
import { API_URLS } from "../constants/apiUrls";
import { LOCALE_MAPPINGS } from "../constants/foodSelectApiMapping";

export const fetchHillsProduct = async (
  petTypeId: number,
  signal: AbortSignal
) => {
  const numericPetTypeId = Number(petTypeId);
  const petType = numericPetTypeId === 1 ? "dog" : "cat";
  const apiUrl = `${process.env.REACT_APP_PET_API_URL}${API_URLS.HILLS_PRODUCTS}`;
  const mappedLocale: string = LOCALE_MAPPINGS[window.hph_locale];

  const params = new URLSearchParams();
  params.append("species", petType);
  params.append("context", mappedLocale);

  const apiResponse = await axios.get(apiUrl, {
    params: params,
    signal: signal,
  });
  return apiResponse;
};
