import React from "react";
import { useNavigate, useSearchParams, type NavigateOptions } from "react-router-dom";

function useSearchPersistantNavigate() {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const searchPersistantNavigate = React.useCallback(
      (to: string,object?:NavigateOptions) => {
        navigate(`${to}?${searchParams.toString()}`,object);
      },
      [navigate, searchParams]
    );
  
    return searchPersistantNavigate;
  }

  export default useSearchPersistantNavigate;

