import { API_URLS } from "../constants/apiUrls";
import axios from "axios";

export async function updateMilestone(data: {
  petId: string | undefined;
  petMilestoneId: string;
  completedDate:
    | null
    | string
    | number
    | {
        from: string;
        to: string;
      };
}) {
  return await axios
    .put(
      `${process.env.REACT_APP_PET_API_URL||""}${API_URLS.PETS}${API_URLS.MILESTONE}`,
      {
        petData: [data],
      }
    )
    .then((response) => {
      if (response.status !== 200) {
        throw new Error("Failed to update milestones");
      }
    });
}
