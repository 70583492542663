import { useToastConfig } from "@components/toast";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import screensets from "../../config/gigya/screensets";
import {
  ACCOUNT_SETTINGS,
  AUTHENTICATION_STATUS,
  ROUTES_PATH,
} from "../../constants";
import useRequireAuth from "../../hooks/useRequireAuth";
import { useSubscribeToGigyaEvent } from "../../hooks/useSubscribeToGigyaEvent";
import gigya from "../../lib/gigya";

export function EditBasicInfo() {
  const auth = useRequireAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { showToast } = useToastConfig();

  const handleCloseModal = useCallback(function handleCloseModal() {
    auth.fetchMyProfileData && auth.fetchMyProfileData();
    showToast({
      description: t(ACCOUNT_SETTINGS.CHANGES_SAVED),
      type: "success",
    });
    navigate(ROUTES_PATH.ACCOUNT_SETTINGS);
  }, []);

  useSubscribeToGigyaEvent({
    type: "onSubmitSuccess",
    screenName: screensets.EDITPROFILE.startScreen,
    callback: handleCloseModal,
  });

  useEffect(() => {
    if (auth?.status === AUTHENTICATION_STATUS.AUTHENTICATED) {
      gigya?.accounts.showScreenSet(screensets.EDITPROFILE);
    }
  }, [auth]);
  if (!auth?.user?.UID) return null;

  return <div id={screensets.EDITPROFILE.containerID} />;
}
