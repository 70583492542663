type CloseIconProps = {
  className?: string;
  width?: string;
  height?: string;
};

export const CloseIcon: React.FC<CloseIconProps> = ({ className = '', width = "12", height = "12" }) => {
  return (
  <svg
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg"
    fill="#0054A4"
    className={className}
    viewBox="0 0 16 16"
    role="img"
    aria-label="X close button"
  >
    <g aria-hidden="true">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.2423 11.6565L5.99963 7.41384L1.75699 11.6565L0.342773 10.2423L4.58541 5.99963L0.342773 1.75699L1.75699 0.342773L5.99963 4.58541L10.2423 0.342773L11.6565 1.75699L7.41384 5.99963L11.6565 10.2423L10.2423 11.6565Z"
    />
    </g>
  </svg>
  );
};