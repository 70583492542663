export const genderIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="inherit"
    aria-label="Gender icon"
  >
      <g aria-hidden="true">
    <path
      d="M10.0218 0V1.68479H11.6496L9.89284 3.66183C9.15987 3.15398 8.2717 2.85429 7.31431 2.85429C5.66576 2.85429 4.22398 3.74013 3.42782 5.05855C3.78629 4.98049 4.15847 4.9382 4.54064 4.9382C4.92884 4.9382 5.3066 4.98234 5.67041 5.06342C6.13598 4.73446 6.70238 4.53954 7.31408 4.53954C8.88944 4.53954 10.1712 5.82056 10.1712 7.39639C10.1712 8.97152 8.88921 10.2537 7.31408 10.2537C5.95198 10.2537 4.81106 9.29444 4.5267 8.01575C3.91105 8.01947 3.35696 8.28292 2.9655 8.70133C3.52749 10.5713 5.26362 11.9385 7.31408 11.9385C9.81873 11.9385 11.8559 9.90103 11.8559 7.39639C11.8559 6.45735 11.5693 5.58359 11.0793 4.85875L12.9063 2.81015V4.56951H14.5913V0H10.0218Z"
      fill="inherit"
    />
      <path
        d="M7.75102 13.3882C8.01657 13.1222 8.24029 12.8269 8.42963 12.5137C8.07023 12.5918 7.69759 12.6345 7.31449 12.6345C6.92629 12.6345 6.54807 12.5906 6.18332 12.5107C5.70567 12.8492 5.13719 13.0334 4.53966 13.0334C3.77649 13.0334 3.05908 12.7363 2.5194 12.1966C1.40589 11.0826 1.40589 9.26958 2.5194 8.15584C3.05908 7.61616 3.77649 7.31902 4.53966 7.31902C5.30237 7.31902 6.02024 7.61592 6.55992 8.15584C6.95021 8.5466 7.21181 9.03076 7.32704 9.55673C7.94431 9.55301 8.49909 9.28863 8.89055 8.86976C8.67821 8.15746 8.2914 7.50441 7.75102 6.96403C6.8933 6.10677 5.75284 5.63377 4.53966 5.63377C3.32648 5.63377 2.18555 6.10654 1.32829 6.96403C-0.442687 8.73524 -0.442686 11.6165 1.32783 13.3879C2.00365 14.064 2.85673 14.4961 3.78113 14.6504V15.9953H1.46164V17.68H3.78113V20H5.46615V17.68H7.78564V15.9953H5.46615V14.6232C6.3255 14.445 7.11632 14.0229 7.75102 13.3882Z"
        fill="inherit"
      />
    </g>
  </svg>
);
