import React, {
  createContext,
  useContext,
  useState,
  useRef,
  useCallback,
} from "react";
import { HillsProductType } from "../../types";
import { useFeatureFlagsConfig } from "./useFeatureFlags";
import { fetchHillsProduct } from "../api-calls/fetchHillsProducts";

type FoodSelectContextProviderProps = {
  children: React.ReactNode;
};

type FoodSelectContext = {
  hillsProducts: HillsProductType[];
  isDataLoading: boolean;
  loadHillsProducts: (petTypeId: number, open: boolean) => void;
};

const FoodSelectDataContext = createContext<FoodSelectContext | null>(null);

export const FoodSelectDataContextProvider = ({
  children,
}: FoodSelectContextProviderProps) => {
  const abortControllerRef = useRef<AbortController | null>(null);
  const [hillsProducts, setHillsProducts] = useState<HillsProductType[]>([]);
  const [productsCache, setProductsCache] = useState<
    Record<number, HillsProductType[]>
  >({});
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [hasFetchedProducts, setHasFetchedProducts] = useState<
    Record<number, boolean>
  >({}); // Added to track if products have been fetched
  const { foodSelect } = useFeatureFlagsConfig();

  // Function to retrieve products from the cache
  const getStoredProducts = useCallback((id: number): HillsProductType[] => {
    return productsCache[id] || [];
  }, [productsCache]);

  // Function to update the cache with new products
  const updateProductsCache = useCallback((id: number, products: HillsProductType[]) => {
    setProductsCache((prevCache) => ({
      ...prevCache,
      [id]: products,
    }));
  }, []);

  const updateHasFetchedProducts = (id: number, fetched: boolean) => {
    setHasFetchedProducts((prevState) => ({
      ...prevState,
      [id]: fetched,
    }));
  };
  const fetchHillsFood = useCallback(async (petTypeId: number) => {
    setIsDataLoading(true);
    const abortController = new AbortController();
    abortControllerRef.current = abortController;
    const apiResponse = await fetchHillsProduct(
      petTypeId,
      abortController.signal
    );
    if (apiResponse.status === 200) {
      const apiResp = apiResponse.data.data;
      
      const filteredProducts = apiResp.filter(
        (product: HillsProductType) => product.primaryProductImageUrl
      );
      updateProductsCache(petTypeId, filteredProducts);
      setHillsProducts(filteredProducts);
      updateHasFetchedProducts(petTypeId, true);
    } else {
      updateProductsCache(petTypeId, []);
      setHillsProducts([]);
      updateHasFetchedProducts(petTypeId, true);
    }
    setIsDataLoading(false);
  }, [updateProductsCache]);  

  const loadHillsProducts = useCallback(
    (petTypeId: number, open: boolean) => {
      const arraysEqual = (a: HillsProductType[], b: HillsProductType[]): boolean => { 
        if (a.length !== b.length) return false;
        for (let i = 0; i < a.length; i++) {
          if (a[i].id !== b[i].id) return false; 
        }
        return true;
      };

      if (open) {
        // Check if the foodSelect feature is enabled for the current locale
        if (!foodSelect || isDataLoading) { 
          return;
        }
        const storedProducts = getStoredProducts(petTypeId);
        const hasFetched = hasFetchedProducts[petTypeId];
        // Only update the state if it's different from the current state and not loading
        if (storedProducts.length > 0 || isDataLoading) {
          if (!arraysEqual(hillsProducts, storedProducts)) { 
            setHillsProducts(storedProducts);
          }
          // Added to handle empty array response
        } else if (storedProducts.length === 0 && hasFetched) {
          // ** Handle empty array response if already fetched
          return;
        } else if (!isDataLoading) {
          // If we don't have products for this petTypeId, fetch them
          fetchHillsFood(petTypeId);
        }
      }
      return () => {
        abortControllerRef.current && abortControllerRef.current.abort();
      };
    },
    [foodSelect, hasFetchedProducts, hillsProducts, isDataLoading, getStoredProducts, fetchHillsFood]
  );

  return (
    <FoodSelectDataContext.Provider
      value={{ hillsProducts, isDataLoading, loadHillsProducts }}
    >
      {children}
    </FoodSelectDataContext.Provider>
  );
};

export function useFoodSelectDataContext() {
  const context = useContext(FoodSelectDataContext);
  if (!context) {
    throw new Error(
      "useFoodSelectDataContext must be used within a FoodSelectDataContextProvider"
    );
  }
  return context;
}
