import { classnames } from "utils";

export function Footer({
  className,
  ...props
}: React.HTMLProps<HTMLDivElement>) {
  return (
    <div
      className={classnames(
        className,
        "absolute bottom-0 left-0 flex w-full flex-col items-center justify-end gap-8",
        "bg-white px-5 pt-6 pb-10 md:flex-row md:gap-6 md:border-t-[1px] md:pb-6"
      )}
      {...props}
    />
  );
}
