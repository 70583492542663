import { datalayerPush } from "../../lib/gtm";
import GTM_ACTIONS from "../../config/gtmActions";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { SideMenuItemType } from "ui-2";

export const CustomSidebarLinks = ({
  item,
  isSubcategory,
}: {
  item: SideMenuItemType;
  isSubcategory: boolean;
}) => {
  const { t } = useTranslation();

  const handleClick = () => {
    if(item.path === '/account-home') {
      datalayerPush(GTM_ACTIONS.LEFT_SIDE_NAV_ACCOUNT_HOME)
    }
    // If the item has an onClick handler, call it
    if (typeof item.onClick === 'function') {
      item.onClick();
    }
  };


  return (
    <NavLink
      data-testid={`navlink`}
      to={item.path}
      className={({ isActive }) =>
        `relative flex w-full overflow-hidden rounded-l-lg 
              border-brand-color-library-gray-200 text-brand-color-library-gray-600 ${
                item.classes
              }
        ${
          isActive && !isSubcategory && "bg-brand-color-library-blue-100"
        } !no-underline`
      }
      onClick={handleClick} 
    >
      {({ isActive }) => (
        <>
          {isActive && !isSubcategory && (
            <div className="absolute h-full w-[4px] bg-brand-color-library-blue-500 "></div>
          )}
          <div
            className={`${
              isSubcategory ? "label2 pl-14" : "label1 px-6"
            } align-stretch flex w-full items-center gap-2 py-5`}
            data-testid={`navlink-${item.title}`}
          >
            {item.icon}
            {t(item.title)}
          </div>
        </>
      )}
    </NavLink>
  );
};
