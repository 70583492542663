import { PetType } from "../../../../../types";
import BasicInfoCard from "../../../../components/BasicInfoCard/BasicInfoCard";
import { usePetConfig } from "../../../../hooks/usePetConfig";

const BasicInfoCardWrapper = ({ petData }: { petData: PetType }) => {
  const { getBreed } = usePetConfig();
  const breedData = getBreed && getBreed(petData.petBreedId || 0);

  return (
    <BasicInfoCard petData={{ ...petData, breedData }} />
  );
};

export default BasicInfoCardWrapper;