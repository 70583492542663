export const AddIcon = () => {
  return (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 23 22"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.4168 6.41668H10.5835V10.0833H6.91683V11.9167H10.5835V15.5833H12.4168V11.9167H16.0835V10.0833H12.4168V6.41668ZM11.5002 1.83334C6.44016 1.83334 2.3335 5.94001 2.3335 11C2.3335 16.06 6.44016 20.1667 11.5002 20.1667C16.5602 20.1667 20.6668 16.06 20.6668 11C20.6668 5.94001 16.5602 1.83334 11.5002 1.83334ZM11.5002 18.3333C7.45766 18.3333 4.16683 15.0425 4.16683 11C4.16683 6.95751 7.45766 3.66668 11.5002 3.66668C15.5427 3.66668 18.8335 6.95751 18.8335 11C18.8335 15.0425 15.5427 18.3333 11.5002 18.3333Z"
      fill="inherit"
    />
  </svg>
)};
