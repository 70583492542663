import { MY_PETS } from "../../../../constants/myPets";

export function getLifestageCode({
  petType,
  lifestageId,
}: {
  petType: string;
  lifestageId: number;
}) {
  const lifestages: Record<number, string> = {
    1: petType === "dog" ? MY_PETS.MILESTONES.PUPPY : MY_PETS.MILESTONES.KITTEN,
    2: MY_PETS.MILESTONES.ADULT,
    3: MY_PETS.MILESTONES.MATURE_ADULT,
  };

  return lifestages[lifestageId];
}
