export const MESSAGES = {
  PET_REMOVAL_REASON_TITLE: "addEditPet.petRemovalReasonTitle",
  PET_HIDE_CONFIRM_TITLE: "addEditPet.petHideConfirmTitle",
  PET_REMOVAL_REASON_DESC: "addEditPet.petRemovalReasonDesc",
  PET_HIDE_REASON_TITLE: "addEditPet.petHideReasonTitle",
  PET_HIDE_REASON_DESC: "addEditPet.petHideReasonDesc",
  PET_REMOVAL_SAVE_LABEL: "addEditPet.petRemovalSaveLabel",
  PET_REMOVAL_CONFIRMATION_SAVE_LABEL: "addEditPet.petRemovalConfirmSaveLabel",
  PET_HIDE_CONFIRMATION_SAVE_LABEL: "addEditPet.petHideConfirmSaveLabel",
  PET_REMOVAL_CANCEL_LABEL: "addEditPet.petRemovalCancelLabel",
  PET_REMOVAL_OPTION_PERMANENT: "addEditPet.petRemovalOptionPermanent",
  PET_REMOVING_PERMANENT_DESC: "addEditPet.petRemovingPermanentDesc",
  PET_REMOVING_PERMANENT_DESC_SEC: "addEditPet.petRemovingPermanentDescSec",
  PET_HIDE_PERMANENT_DESC: "addEditPet.petHidePermanentDesc",
  PET_HIDE_PERMANENT_DESC_SEC: "addEditPet.petHidePermanentDescSec",
  PET_HIDE_CTA_LABEL: "addEditPet.petHideCtaLabel",
  PET_SAVE_CTA_LABEL: "addEditPet.petSaveCtaLabel",
  PET_REMOVAL_REASON: "addEditPet.petRemovalReason",
  CHOOSE_A_REASON_FOR_REMOVAL: "addEditPet.chooseReasonForRemoval",
  DECEASED: "addEditPet.deceased",
  REHOMED: "addEditPet.rehomed",
  PET_REMOVED_MESSAGE: "addEditPet.petRemovedMessage",
  PET_ADDED_MESSAGE: "addEditPet.petAddedMessage",
  PET_UPDATE_MESSAGE: "addEditPet.petUpdateMessage",
  PROFILE_UPDATE_MESSAGE: "editProfile.profileUpdateMsg",
  PROFILE_PIC_EXTENSION_ERROR: "editProfile.profilePicExtError",
  PROFILE_PIC_SIZE_ERROR: "editProfile.profilePicSizeErr",
  PROFILE_PIC_UPDATE_MESSAGE: "editProfile.profilePicUpdateMsg",
  PROFILE_PIC_MULTI_PERIOD_ERROR: "editProfile.profilePicMultiPeriod",
  MAIN_BOOTSTRAP_LOADER: "main.bootstrapLoader",
  ERROR: "addEditPet.error"
};
