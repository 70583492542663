export const RememberedPetsWhite = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <g id="Remembered">
      <g id="Vector">
        <path
          d="M1.09131 18C1.694 18 2.1762 17.5095 2.24236 16.9105C2.78447 12.0015 6.94671 8.18252 12 8.18252C17.0534 8.18252 21.2156 12.0015 21.7577 16.9105C21.8239 17.5095 22.3061 18 22.9088 18C23.5115 18 24.0052 17.5098 23.9511 16.9096C23.3997 10.7954 18.2572 6 12 6C5.74293 6 0.600379 10.7954 0.0489876 16.9096C-0.00514442 17.5098 0.488624 18 1.09131 18Z"
          fill="white"
        />
        <path
          d="M5.45635 18C6.05903 18 6.53622 17.5065 6.65576 16.9158C7.15956 14.4261 9.3635 12.5476 12 12.5476C14.6366 12.5476 16.8405 14.4261 17.3443 16.9158C17.4639 17.5065 17.9411 18 18.5438 18C19.1464 18 19.6432 17.5086 19.5582 16.912C19.0305 13.2093 15.8491 10.365 12 10.365C8.15095 10.365 4.96959 13.2093 4.44193 16.912C4.3569 17.5086 4.85366 18 5.45635 18Z"
          fill="white"
        />
        <path
          d="M9.16373 17.018C9.56939 15.842 10.6842 15 12 15C13.3159 15 14.4307 15.842 14.8364 17.018C15.0165 17.54 14.5523 18 14 18H10C9.44777 18 8.98362 17.54 9.16373 17.018Z"
          fill="white"
        />
      </g>
    </g>
  </svg>
);
