import { ChevronRight } from "@icons/ChevronRight";
import { PetType } from "@types";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swiper from "swiper";
import { register } from "swiper/element/bundle";
import { MyPetsHeader, defaultImage } from "./common";
import { swiperDesktopConfig } from "./SwiperConfig";
import { SwiperSlide } from "./SwiperSlide";

register();
interface SwiperRememberedPetsProps {
  petsList: Array<PetType>;
  isMobile: boolean;
  petId?: string;
}

// Define the type for the Swiper container
type SwiperContainerElement = HTMLElement & {
  swiper: Swiper;
  initialize: () => void;
};

export const SwiperRememberedPets = (
  swiperProps: SwiperRememberedPetsProps
) => {
  const { petsList, isMobile, petId } = swiperProps;
  const navigate = useNavigate();
  const swiperElRef = useRef<SwiperContainerElement>(null);
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);
  const [selectedPet, setSelectedPet] = useState<PetType | null>(null);
  const sixPetsCriteria = petsList.length <= 6;
  const showAddPetButtonInHeader = petsList.length >= 6;

  const onSlideClick = (pet: PetType) => {
    setSelectedPet(pet);
    navigate(`/mypets/?petId=${pet.petId}`);
  };

  const initializeDesktopSwiper = useCallback(() => {
    if (swiperElRef.current && !swiperElRef.current?.swiper) {
      const swiperJsConfig = {
        pagination: {
          clickable: true,
        },
        on: {
          slideChange: function (swiper: Swiper) {
            swiper.isEnd ? setIsEnd(true) : setIsEnd(false);
            swiper.isBeginning ? setIsBeginning(true) : setIsBeginning(false);
          },
          init: function (swiper: Swiper) {
            if (swiper) {
              let petIndex = petsList.findIndex((pet) => pet.petId === petId);
              petIndex = petIndex > -1 ? petIndex : 0;
              setSelectedPet(petsList[petIndex]);
              swiper.slideTo(petIndex, 0, false);
            }
          },
        },
      };

      Object.assign(swiperElRef.current, {
        ...swiperDesktopConfig,
        ...swiperJsConfig,
      });
      swiperElRef.current.initialize();
    }
  }, []);

  useEffect(() => {
    if (swiperElRef.current) {
      initializeDesktopSwiper();
    }
  }, [initializeDesktopSwiper]);

  const goToNextSlide = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    if (swiperElRef.current) {
      swiperElRef.current.swiper.slideNext();
    }
  };

  const goToPrevSlide = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    if (swiperElRef.current) {
      swiperElRef.current.swiper.slidePrev();
    }
  };

  return (
    <div data-testid={"SwiperRememberedPets"}>
      {
        <MyPetsHeader
          showAddPetButtonInHeader={showAddPetButtonInHeader}
          isMobile={isMobile}
          sixPetsCriteria={sixPetsCriteria}
        />
      }
      <div className="mx-11 flex flex-col">
        <div className="flex items-center justify-center">
          <div
            className={`h-[2.625rem] w-[2.625rem]  rounded-full border p-2  ${
              isBeginning ? "cursor-not-allowed opacity-40" : "cursor-pointer"
            }`}
          >
            <div
              className={`h-6 w-6 flex-none rotate-180`}
              onClick={goToPrevSlide}
            >
              <ChevronRight className="fill-brand-color-library-blue-500" />
            </div>
          </div>

          <div className="m-2 w-full grow">
            <swiper-container init={false} ref={swiperElRef}>
              {petsList.map((pet: PetType, index: number) => {
                return (
                  <swiper-slide key={pet.petId + index}>
                    <div className="m-2 flex h-[110px] items-center">
                      <SwiperSlide
                        petObj={pet}
                        isSelected={selectedPet?.petId === pet.petId}
                        onSlideClick={() => onSlideClick(pet)}
                        defaultImage={defaultImage}
                        isMobile={isMobile}
                        sixPetsCriteria={sixPetsCriteria}
                      />
                    </div>
                  </swiper-slide>
                );
              })}
            </swiper-container>
          </div>
          <div
            className={`h-[2.625rem] w-[2.625rem]  rounded-full border p-2 ${
              isEnd ? "cursor-not-allowed opacity-40" : "cursor-pointer"
            }`}
          >
            <div className={`h-6 w-6 flex-none`} onClick={goToNextSlide}>
              <ChevronRight className="fill-brand-color-library-blue-500" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
