export const searchIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    aria-hidden={true}
  >
    <g aria-hidden="true">
      <path
        d="M20.5887 18.5972L16.3805 14.3894C17.3374 12.9689 17.8096 11.194 17.5568 9.30283C17.1258 6.08603 14.4891 3.46822 11.2687 3.06056C6.48079 2.45477 2.45468 6.48044 3.06057 11.2679C3.46838 14.4892 6.08683 17.1278 9.30434 17.5566C11.1958 17.8093 12.9712 17.3374 14.3915 16.3804L18.5997 20.5882C19.1488 21.1373 20.0393 21.1373 20.5885 20.5882C21.1371 20.0384 21.1371 19.1455 20.5887 18.5972ZM5.78083 10.3117C5.78083 7.83064 7.79951 5.81219 10.2808 5.81219C12.7622 5.81219 14.7809 7.83064 14.7809 10.3117C14.7809 12.7928 12.7622 14.8112 10.2808 14.8112C7.79951 14.8112 5.78083 12.7935 5.78083 10.3117Z"
        fill="#66676B"
      />
    </g>
  </svg>
);
