import { Button } from "ui-2";
import { useTranslation } from "react-i18next";
import { heartIcon } from "../../icons/heart";

export default function CondolenceModal({ onClose }: { onClose: () => void }) {
  const { t } = useTranslation();

  return (
    <>
      <div
        className="mx-5 mt-8 mb-10 overflow-hidden lg:mx-[230px]"
        data-testid="pet-type"
      >
        <span>{t("myPets.hideDeletePet.condolenceText1")}</span>
        <br />
        <br />
        <span>{t("myPets.hideDeletePet.condolenceText2")}</span>
        <div className="mt-8 flex justify-center">{heartIcon}</div>
      </div>
      <div className="position-fixed sticky bottom-0 bg-white px-5 md:border-t-[1px]">
        <div className="flex h-20 flex-col items-stretch gap-5 md:flex-row-reverse md:items-center">
          <Button
            type="submit"
            data-testid="save-button"
            classes="md:w-[207px]"
            onClick={onClose}
          >
            {t("myPets.hideDeletePet.close")}
          </Button>
        </div>
      </div>
    </>
  );
}