import React, { useEffect, useRef } from "react";
import { FetchRecommendedArticles } from "../../api-calls";
import { Article } from "../../../types";
import { ArticleCard } from "ui-2";
import { useTranslation } from "react-i18next";
import { datalayerPush } from "@lib/gtm";
import GTMACTIONS from "@config/gtmActions";

const RecommendedArticles = () => {
  const { fetchArticleList, data } = FetchRecommendedArticles();
  const { t } = useTranslation();

  const initialized = useRef(false);
  const abortControllerRef = useRef<AbortController | null>(null);

  const gaClickArticle = (article:Article) => {
    const GAclickEvent = {
      ...GTMACTIONS.ARTICLES,
      "article_title": article?.title,
      "click_text": article?.title,
      "click_url": article?.url
    }
    datalayerPush(GAclickEvent);
  }

  useEffect(() => {
    abortControllerRef.current && abortControllerRef.current.abort();
    if (!initialized.current) {
      initialized.current = true;
      fetchArticleList();
    }

    return () => {
      abortControllerRef.current && abortControllerRef.current.abort();
    };
  }, []);

  return (
    <>
      {data?.data?.data ? (
        <div className="label2 lg:headline5 flex flex-col items-start gap-4 px-5 lg:px-0">
          {t(`addEditPet.recommendedArticles`)}
          <div
            className="grid_hphv2 w-full gap-6 md:grid-cols-2"
            role="list"
            aria-label="Recommended Articles"
          >
            {data?.data.data.map((article: Article) => (
              <div onClick={()=>gaClickArticle(article)}  key={article.id} className="lg:96">
                <ArticleCard article={article} />
              </div>
              
            ))}
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default RecommendedArticles;
