export function GearIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="32"
      height="33"
      viewBox="0 0 32 33"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden
      {...props}
    >
      <path d="M25.9067 17.8067C25.96 17.38 26 16.9533 26 16.5C26 16.0467 25.96 15.62 25.9067 15.1933L28.72 12.9933C28.9734 12.7933 29.04 12.4333 28.88 12.14L26.2134 7.52666C26.0534 7.23332 25.6934 7.12666 25.4 7.23332L22.08 8.56666C21.3867 8.03332 20.64 7.59332 19.8267 7.25999L19.32 3.72666C19.28 3.40666 19 3.16666 18.6667 3.16666H13.3334C13 3.16666 12.72 3.40666 12.68 3.72666L12.1734 7.25999C11.36 7.59332 10.6134 8.04666 9.92003 8.56666L6.60003 7.23332C6.29336 7.11332 5.9467 7.23332 5.7867 7.52666L3.12003 12.14C2.9467 12.4333 3.0267 12.7933 3.28003 12.9933L6.09336 15.1933C6.04003 15.62 6.00003 16.06 6.00003 16.5C6.00003 16.94 6.04003 17.38 6.09336 17.8067L3.28003 20.0067C3.0267 20.2067 2.96003 20.5667 3.12003 20.86L5.7867 25.4733C5.9467 25.7667 6.3067 25.8733 6.60003 25.7667L9.92003 24.4333C10.6134 24.9667 11.36 25.4067 12.1734 25.74L12.68 29.2733C12.72 29.5933 13 29.8333 13.3334 29.8333H18.6667C19 29.8333 19.28 29.5933 19.32 29.2733L19.8267 25.74C20.64 25.4067 21.3867 24.9533 22.08 24.4333L25.4 25.7667C25.7067 25.8867 26.0534 25.7667 26.2134 25.4733L28.88 20.86C29.04 20.5667 28.9734 20.2067 28.72 20.0067L25.9067 17.8067ZM16 21.1667C13.4267 21.1667 11.3334 19.0733 11.3334 16.5C11.3334 13.9267 13.4267 11.8333 16 11.8333C18.5734 11.8333 20.6667 13.9267 20.6667 16.5C20.6667 19.0733 18.5734 21.1667 16 21.1667Z" />
    </svg>
  );
}
