export function ZoomIn(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}>
      <g id="Zoom In">
        <path
        id="Vector"
        d="M15.4999 14.0001H14.7099L14.4299 13.7301C15.6299 12.3301 16.2499 10.4201 15.9099 8.39014C15.4399 5.61014 13.1199 3.39014 10.3199 3.05014C6.0899 2.53014 2.5399 6.09014 3.0499 10.3201C3.3899 13.1201 5.6099 15.4401 8.3899 15.9101C10.4199 16.2501 12.3299 15.6301 13.7299 14.4301L13.9999 14.7101V15.5001L18.2599 19.7501C18.6699 20.1601 19.3299 20.1601 19.7399 19.7501L19.7499 19.7401C20.1599 19.3301 20.1599 18.6701 19.7499 18.2601L15.4999 14.0001ZM9.4999 14.0001C7.0099 14.0001 4.9999 11.9901 4.9999 9.50014C4.9999 7.01014 7.0099 5.00014 9.4999 5.00014C11.9899 5.00014 13.9999 7.01014 13.9999 9.50014C13.9999 11.9901 11.9899 14.0001 9.4999 14.0001ZM9.4999 7.00014C9.2199 7.00014 8.9999 7.22014 8.9999 7.50014V9.00014H7.4999C7.2199 9.00014 6.9999 9.22014 6.9999 9.50014C6.9999 9.78014 7.2199 10.0001 7.4999 10.0001H8.9999V11.5001C8.9999 11.7801 9.2199 12.0001 9.4999 12.0001C9.7799 12.0001 9.9999 11.7801 9.9999 11.5001V10.0001H11.4999C11.7799 10.0001 11.9999 9.78014 11.9999 9.50014C11.9999 9.22014 11.7799 9.00014 11.4999 9.00014H9.9999V7.50014C9.9999 7.22014 9.7799 7.00014 9.4999 7.00014Z"
        fill="#66676B"/>
      </g>
    </svg>
  );
}
