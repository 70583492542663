import { MY_PETS } from "../../../../constants/myPets";
import { ChevronRight } from "../../../../icons/ChevronRight";
import React from "react";
import { useTranslation } from "react-i18next";
import { SwiperContainer } from "swiper/element";

export function LifestageSwiper({
  lifestageIdx,
  setLifestageIdx,
  lifestageLabels,
}: {
  lifestageIdx: number;
  setLifestageIdx: React.Dispatch<React.SetStateAction<number>>;
  lifestageLabels: string[];
}) {
  const { t } = useTranslation();
  const ref = React.useRef<SwiperContainer>(null);

  const activeIndex = lifestageIdx - 1;

  const handleNext = React.useCallback(
    function handleNext() {
      setLifestageIdx((l) => Math.min(3, l + 1));
    },
    [setLifestageIdx]
  );
  const handlePrev = React.useCallback(
    function handlePrev() {
      setLifestageIdx((l) => Math.max(1, l - 1));
    },
    [setLifestageIdx]
  );

  React.useEffect(() => {
    ref.current?.swiper.slideTo(activeIndex);
  }, [activeIndex]);

  const controlStyles =
    "absolute top-0 fill-brand-color-library-gray-600 disabled:fill-brand-color-library-gray-500 disabled:cursor-not-allowed";

  return (
    <div className="relative mx-auto mb-8 h-6 max-w-[320px] px-6">
      <button
        disabled={activeIndex === 0}
        className={"left-0 rotate-180 " + controlStyles}
        onClick={handlePrev}
        aria-label="Previous"
      >
        <ChevronRight />
      </button>
      <swiper-container slides-per-view="1" ref={ref} active-index={2}>
        {lifestageLabels.map((l) => (
          <swiper-slide key={l}>
            <div className="body3 md:body2 text-center text-brand-color-library-gray-600">
              {t(MY_PETS.MILESTONES.LIFESTAGE)}{" "}
              <b className="label2 md:label1 text-brand-color-library-gray-900">
                {l}
              </b>
            </div>
          </swiper-slide>
        ))}
      </swiper-container>
      <button
        disabled={activeIndex === lifestageLabels.length - 1}
        className={"right-0 " + controlStyles}
        onClick={handleNext}
        aria-label="Next"
      >
        <ChevronRight />
      </button>
    </div>
  );
}
