import { getDaysInMonth, isAfter, isBefore, isValid } from "date-fns";
import { DateValidatorErrorList } from "./DateValidatorErrorList.class";

// TODO: Add translations for each error messages

type DateValidator = (dateObj: {
  day?: string;
  month?: string;
  year?: string;
  min?: Date;
  max?: Date;
}) => { isValid: boolean; errors?: DateValidatorErrorList };

export const validateDate: DateValidator = ({ day, month, year, min, max }) => {
  const errors = new DateValidatorErrorList();

  // No validation if all fields are empty
  if (!day && !month && !year) {
    return { isValid: true };
  }

  if (month !== undefined) {
    const monthNum = parseInt(month, 10);
    if (isNaN(monthNum) || monthNum < 1 || monthNum > 12) {
      errors.addMonthError("Invalid month. Month should be between 1 and 12.");
    }
  }

  if (year !== undefined) {
    const yearNum = parseInt(year, 10);
    if (isNaN(yearNum) || yearNum < 1000) {
      errors.addYearError("Invalid year. Year should be greater than 1000.");
    }
  }

  if (day !== undefined && month !== undefined && year !== undefined) {
    const monthNum = parseInt(month, 10);
    const yearNum = parseInt(year, 10);
    const dayNum = parseInt(day, 10);

    if (
      isNaN(dayNum) ||
      dayNum < 1 ||
      (monthNum &&
        yearNum &&
        dayNum > getDaysInMonth(new Date(yearNum, monthNum - 1)))
    ) {
      errors.addDayError(
        `Invalid day. Day should be between 1 and ${monthNum && yearNum
          ? getDaysInMonth(new Date(yearNum, monthNum - 1))
          : "the number of days in the month"
        }.`
      );
    }
  }

  if (errors.hasErrors()) {
    const dateStr = `${year}-${month}-${day}`;
    const parsedDate = new Date(dateStr);

    if (!isValid(parsedDate)) {
      errors.addDateError("Invalid date format.");
    }

    if (min && isBefore(parsedDate, min)) {
      errors.addDateError(
        `Date should not be before ${min.toISOString().split("T")[0]}.`
      );
    }

    if (max && isAfter(parsedDate, max)) {
      errors.addDateError(
        `Date should not be after ${max.toISOString().split("T")[0]}.`
      );
    }
  }

  if (errors.hasErrors()) {
    return { isValid: false, errors };
  }

  return { isValid: true };
};