export const leftIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <g id="Left Arrow">
      <path
        id="Left arrow"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.6998 7.4L14.2998 6L8.2998 12L14.2998 18L15.6998 16.6L11.0998 12L15.6998 7.4Z"
        fill="white"
      />
    </g>
  </svg>
)
