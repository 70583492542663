import axios from "axios";
import { PetType } from "../../types";
import { API_URLS } from "../constants/apiUrls";

export async function fetchPetById(petId: string) {
  return await axios
    .get<{ data: PetType[] }>(
      `${process.env.REACT_APP_PET_API_URL}${API_URLS.PETS}/${petId}`
    )
    .then((response) => {
      if (response.status !== 200) {
        throw new Error("Failed to fetch pet");
      } else {
        return (response.data?.data || []).map((d) => ({
          ...d,
          petType: d.petTypeId === 1 ? "dog" : "cat",
        }));
      }
    });
}
