import { useTranslation } from "react-i18next";
import { ACCOUNT_HOME } from "../../constants";
import { DogCat } from "../../icons/DogCat";

interface UserObjType {
  firstName: string;
  lastName: string;
  email: string;
  photoURL: string;
}

interface userProps {
  user: UserObjType | null;
}

export const WelcomeUser: React.FC<userProps> = (props) => {
  const { user } = props;
  const { firstName } = user || {};
  const { t } = useTranslation();

  return (
    <div className="mb-8 flex w-full items-end justify-between bg-[#003672] px-5 pt-4 lg:mb-0 lg:bg-white">
      <div className="pb-8">
        <span className="headline4 lg:headline3 text-white lg:text-black">
          {t(ACCOUNT_HOME.WelcomeHeaderHeading, { userName: firstName })}
        </span>
      </div>
      <div className="w-32 scale-x-[-1] fill-[#DDEAFE] lg:fill-[#0054A4]">
        {DogCat}
      </div>
    </div>
  );
};

WelcomeUser.displayName = "WelcomeUser";
WelcomeUser.defaultProps = {
  user: {
    firstName: "",
    lastName: "",
    email: "",
    photoURL: "",
  },
};

export default WelcomeUser;
