import React from "react";

export function Alert(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="56"
      height="56"
      viewBox="0 0 56 56"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="56" height="56" rx="28" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M51.3332 28.0001C51.3332 40.8867 40.8865 51.3334 27.9998 51.3334C15.1132 51.3334 4.6665 40.8867 4.6665 28.0001C4.6665 15.1134 15.1132 4.66675 27.9998 4.66675C40.8865 4.66675 51.3332 15.1134 51.3332 28.0001ZM28.0162 39.6428C29.0833 39.6428 29.9653 39.3006 30.6622 38.6163C31.3591 37.932 31.7075 37.0963 31.7075 36.1091C31.7075 35.0995 31.3645 34.2694 30.6785 33.6187C29.9925 32.9681 29.1051 32.6428 28.0162 32.6428C26.9273 32.6428 26.0344 32.9681 25.3375 33.6187C24.6406 34.2694 24.2922 35.0995 24.2922 36.1091C24.2922 37.0963 24.6406 37.932 25.3375 38.6163C26.0344 39.3006 26.9273 39.6428 28.0162 39.6428ZM31.7075 16.3334L30.4662 30.3303H25.5335L24.3248 16.3334H31.7075Z"
        fill="#C4132A"
      />
    </svg>
  );
}
