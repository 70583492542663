import { Dispatch, SetStateAction } from "react";
import { useSearchParams } from "react-router-dom";
import { useCallback } from "react";

interface FoodSelectionHandlerProps {
  petId: string | null;
  setSearchParams: ReturnType<typeof useSearchParams>[1];
  refetchPet: () => void;
  setIsFoodTypeModalOpen: Dispatch<SetStateAction<boolean>>;
}

export const useFoodSelectionHandler = ({
  petId,
  setSearchParams,
  refetchPet,
  setIsFoodTypeModalOpen,
}: FoodSelectionHandlerProps) => {
  const handleFoodSelectionSaved = useCallback(() => {
    setIsFoodTypeModalOpen(false); 
    setSearchParams({ petId: petId || "", tab: "Diet" });
    refetchPet(); 
  }, [petId, setIsFoodTypeModalOpen, setSearchParams, refetchPet]);

  return {
    handleFoodSelectionSaved,
  };
};